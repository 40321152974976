import React, {useEffect, useState} from 'react';
import DefaultModal, {DeleteModal} from "../../../components/modal";
import {useDispatch, useSelector} from "react-redux";
import {ThunkDispatch} from "@reduxjs/toolkit";
import {useAppSelector} from "../../../redux/hooks";
import {RootState} from "../../../redux/store";
import Product from "../../../models/product";
import {DefaultResponse} from "../../../redux/mainSlice";
import {getMediaTypes, MediaType} from "../../../models/media";
import {ProductUpdateCredentials, remove, update} from "../../../redux/dashboard/products/productsAction";
import {
    IconArrowLeft,
    IconArrowRight,
    IconFileLike,
    IconHeart,
    IconHeartFilled,
    IconInfoSquare
} from "@tabler/icons-react";
import {serverAsset} from "../../../services/connectionConfig";
import {addToCart} from "../../../redux/dashboard/cart/addCartAction";
import dLoad from "../../../assets/img/cantLoadImage.webp";
import {getSettingByKey, moneyFormat, NetworkImage, toastSuccess} from "../../../utils/funcions";
import {Link, useLocation} from "react-router-dom";
import Routes from "../../../utils/routing/routes";
import EditProductModal from "../products/edit";
import {MdOutlineMoreHoriz, MdOutlineMoreVert} from "react-icons/md";
import {AiOutlineDelete, AiOutlineEdit} from "react-icons/ai";
import Category from "../../../models/category";
import HighLightedProducts from "../../../models/highLightedProducts";
import {LoadingTableShimmer} from "../../../components/basincComponents";
import {submit} from "../../../redux/user/login/loginAction";
import {submitLike} from "../../../redux/dashboard/like/likeAction";
import products from "../products";


interface values {
    title: string;
    description: string;
    price: number;
    categoryId: number;
    quantity: number;
    // highLightId: number,
}



function ProductPageModal() {


    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
    const {submitLoading, product, productsList, loading} = useAppSelector((state: RootState) => state.adminProducts);
    const {highLights} = useAppSelector((state: RootState) => state.highlights);
    const { like } = useAppSelector((state: RootState) => state.like);

    // const [isLike, setIsLike] = useState(() => {
        // const existingLike = like.find(l => l.product_id === product.id);
        // return existingLike ? existingLike.is_like : false;
    // });

    // const handleSetLike = async (product_id: number, newIsLike: boolean) => {
    //     setIsLike(newIsLike); // به‌روزرسانی محلی وضعیت لایک
    //     await dispatch(submitLike({ product_id, is_like: newIsLike }));
    // };
    const {shop, settings, user, isMerchantFilled} = useAppSelector((state: RootState) => state.user);
    //edit
    const changeCartValues = (id: number) => {
        if (submitLoading) return;
        dispatch(addToCart({
            quantity: 1,
            productId: id
        }));
    }

    const [editingItem, setEditingItem] = useState<Product | null>(null);
    const location = useLocation();
    const {pathname} = location;

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState<Product | HighLightedProducts | null>(null);
    const handleDeleteClick = (product: Product | HighLightedProducts) => {
        setSelectedItem(product);
        setIsModalOpen(true);
    };

    const handleDeleteConfirm = async () => {
        if (selectedItem) {
            // setDeletedId(selectedItem.id);
            setSelectedItem(null);
            setIsModalOpen(false);
            const result: DefaultResponse = (await dispatch(remove(selectedItem.id))).payload as DefaultResponse;
            if (result.status !== 200 && result.status !== 201) {
                // setDeletedId(0);
            }

        }
        // eslint-disable-next-line no-restricted-globals
        history.back();
    };
    // const [isFullSize, setIsFullSize] = useState(false);
    // const [isLike, setIsLike] = useState(false);

    // useEffect(() => {
    //     const userLike = product?.like?.find(e => e.user_id === user?.id);
    //     if (userLike) {
    //         setIsLike(userLike.is_like);
    //     }
    // }, [product, user, like]);

    // const handleSetLike = async (product_id: number, is_like: boolean) => {
    //     setIsLike(is_like);
    //     await dispatch(submitLike({ is_like, product_id }));
    // };


    const [price, setPrice] = useState(product?.price);
    const [selectedKey, setSelectedKey] = useState(null);
    const [selectedValue, setSelectedValue] = useState(null);

    const handleAttributeClick = (key: string | React.SetStateAction<null> | undefined, value: string | React.SetStateAction<number | undefined> | React.SetStateAction<null>) => {
        if (selectedKey === key) {
            setSelectedKey(null);
            setSelectedValue(null);
            setPrice(product?.price);
        } else {
            // @ts-ignore
            setSelectedKey(key);
            // @ts-ignore
            setSelectedValue(value);
            // @ts-ignore
            setPrice(value);
        }
    };
    return (
        <div>
            <div className={'overflow-x-hidden py-3 w-full md:block hidden h-full flex-col justify-center'}>

                <DeleteModal
                    title="حذف پست"
                    message="آیا از حذف این پست مطمئن هستید؟"
                    onSubmit={handleDeleteConfirm}
                    isOpen={isModalOpen}
                    handleIsOpen={setIsModalOpen}
                ></DeleteModal>
                <EditProductModal
                    product={editingItem}
                    onClose={(isOpen: boolean) => {
                        setEditingItem(null);
                    }}
                ></EditProductModal>
                {/*//تو با قلبت ببیا من با دلم*/}
                <div
                    className={'cursor-pointer fixed top-0 z-50 py-2 bg-white w-full md:hidden flex items-center'}>
                    <Link to={Routes.products.path}
                          className={'hover:bg-[#4192EF33] rounded-full h-2 w-2 '}><IconArrowRight/></Link>
                    <div className={'md:hidden flex'}>
                        {
                            shop!.avatar == null ? <img
                                    className="w-[42px] h-[42px] rounded-full"
                                    src={dLoad}
                                    alt={""}/> :
                                <Link to={Routes.aboutUs.path} className="rounded-full">
                                    <div
                                        className={`rounded-full relative ${highLights! != null && highLights!.length != 0 ? "bg-gradient-to-b from-[#DE0046] to-[#F7A34B]" : "bg-white"} flex w-[47px] h-[47px] justify-center items-center `}>

                                        <div
                                            className='rounded-full relative bg-white flex w-[44px] h-[43px] justify-center items-center'>

                                            <NetworkImage className={'w-[42px] h-[42px] rounded-full'}
                                                          url={shop!.avatar}
                                                          alt={''}/>
                                        </div>
                                    </div>
                                </Link>
                        }
                        <div className={'flex flex-col justify-start mr-2'}>
                                                <span
                                                    className={'text-[14px]'}>{settings.find(e => e.name === 'shop_name')?.value}</span>
                            <span
                                className={'text-[12px] mt-0.5 ml-2'}>{shop!.description != null ? shop!.description.length > 40 ? shop!.description.substring(0, 40) + '...' : shop!.description : "بیوگرافی ندارد."}</span>
                        </div>
                    </div>
                </div>
                {
                    loading || product === null || product === undefined ? <div></div> :
                        <div className={'md:flex px-4  md:flex-row flex flex-col w-full h-full mt-12 justify-between'}>
                            <div className={'flex justify-between w-full'}>
                                <div className="flex flex-col h-full items-center justify-start w-full mt-2">
                                    <div className={'flex items-start justify-between w-full mt-2 pl-4'}>

                                        <div
                                            className="md:hidden flex cursor-pointer w-4 h-4 transition-all duration-300">

                                            {user!.id === shop!.id && (
                                                <div className="rounded-lg">
                                                    <div className="dropdown dropdown-left p-1 rounded-lg">
                                                        <label tabIndex={0} className="bg-transparent cursor-pointer ">
                                                            <MdOutlineMoreHoriz size={24} color="gray   "/>
                                                        </label>
                                                        <ul tabIndex={0}
                                                            className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-44">
                                                            <li
                                                                onClick={() => {
                                                                    setEditingItem(product);
                                                                }}
                                                                className="px-1 p-0 bg-transparent border-none cursor-pointer hover:bg-gray-400 hover:bg-opacity-50 rounded-lg items-start">
                                                                <div
                                                                    className="px-1 bg-transparent hover:bg-transparent">
                                                                    <AiOutlineEdit
                                                                        onClick={() => {
                                                                            setEditingItem(product);
                                                                        }
                                                                        }
                                                                        size={24}/>
                                                                    ویرایش
                                                                </div>
                                                            </li>
                                                            <li

                                                                onClick={() => {
                                                                    handleDeleteClick(product);
                                                                }}
                                                                className="px-1 p-0 bg-transparent border-none cursor-pointer hover:bg-gray-400 hover:bg-opacity-50 rounded-lg items-start">
                                                                <div
                                                                    className="px-1 text-[#DE0046] bg-transparent hover:bg-transparent">
                                                                    <AiOutlineDelete size={24}/>
                                                                    حذف
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>)}
                                        </div>
                                    </div>
                                    {product!.media.length == 0 ? <img
                                            className="h-[450px] md:w-[400px] object-cover rounded-xl"
                                            src={dLoad}
                                            alt={""}/> :
                                        <div className="carousel w-full">
                                            {
                                                product.media
                                                    .map((e, i) => (
                                                        <div id={`slide${i + 2}`} // تنظیم آیدی برای هر اسلاید
                                                             className="carousel-item flex relative w-full mr-2">
                                                            <div className="relative w-full">
                                                                <img
                                                                    src={serverAsset(e.thumbnail)}
                                                                    alt=""
                                                                    className="absolute object-contain w-full h-full transition duration-300 z-20 ease-in-out transform" // No blur here
                                                                />
                                                                <img
                                                                    src={serverAsset(e.url)}
                                                                    alt=""
                                                                    className="object-cover w-full carousel-item flex justify-center h-[500px] items-center inset-0 bg-cover bg-no-repeat blur-lg z-10"
                                                                />
                                                            </div>
                                                        </div>
                                                    ))
                                            }

                                        </div>
                                    }
                                </div>
                            </div>
                            <div className={'md:w-full md:mr-4 flex flex-col justify-between'}>
                                <div>
                                    <div className={'md:flex hidden items-start justify-between mt-4'}>
                                        <div className={'flex'}>
                                            {
                                                shop!.avatar == null ? <img
                                                        className="w-[50px] h-[50px] rounded-full"
                                                        src={dLoad}
                                                        alt={""}/> :

                                                    <Link to={Routes.aboutUs.path} className="rounded-full">
                                                        <div
                                                            className={`rounded-full relative ${highLights! != null && highLights!.length != 0 ? "bg-gradient-to-b from-[#DE0046] to-[#F7A34B]" : "bg-white"} flex w-[47px] h-[47px] justify-center items-center `}>

                                                            <div
                                                                className='rounded-full relative bg-white flex w-[44px] h-[43px] justify-center items-center'>

                                                                <NetworkImage className={'w-[42px] h-[42px] rounded-full'}
                                                                              url={shop!.avatar}
                                                                              alt={''}/>
                                                            </div>
                                                        </div>
                                                    </Link>
                                            }

                                            <div className={'flex flex-col justify-start mr-2'}>
                                            <span
                                                className={'text-[14px] '}>{settings.find(e => e.name === 'shop_name')?.value}</span>
                                                <span
                                                    className={'text-[12px] mt-1 ml-2'}>{shop!.description != null ? shop!.description.length > 40 ? shop!.description.substring(0, 40) + '...' : shop!.description : "بیوگرافی ندارد."}</span>
                                            </div>
                                        </div>
                                        <div
                                            className="flex cursor-pointer w-4 h-4 transition-all duration-300">

                                            {user!.id === shop!.id && (
                                                <div className="rounded-lg">
                                                    <div className="dropdown dropdown-left p-1 rounded-lg">
                                                        <label tabIndex={0} className="bg-transparent cursor-pointer ">
                                                            <MdOutlineMoreHoriz size={24} color="gray   "/>
                                                        </label>
                                                        <ul tabIndex={0}
                                                            className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-44">
                                                            <li
                                                                onClick={() => {
                                                                    setEditingItem(product);
                                                                }}
                                                                className="px-1 p-0 bg-transparent border-none cursor-pointer hover:bg-gray-400 hover:bg-opacity-50 rounded-lg items-start">
                                                                <div
                                                                    className="px-1 bg-transparent hover:bg-transparent">
                                                                    <AiOutlineEdit
                                                                        onClick={() => {
                                                                            setEditingItem(product);
                                                                        }
                                                                        }
                                                                        size={24}/>
                                                                    ویرایش
                                                                </div>
                                                            </li>
                                                            <li

                                                                onClick={() => {
                                                                    handleDeleteClick(product);
                                                                }}
                                                                className="px-1 p-0 bg-transparent border-none cursor-pointer hover:bg-gray-400 hover:bg-opacity-50 rounded-lg items-start">
                                                                <div
                                                                    className="px-1 text-[#DE0046] bg-transparent hover:bg-transparent">
                                                                    <AiOutlineDelete size={24}/>
                                                                    حذف
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>)}
                                        </div>
                                    </div>

                                    <div className={
                                        'md:hidden flex w-full justify-center mt-2'
                                    }>
                                        {
                                            product!.media.map((media, e) =>
                                                <div className={'w-2 h-2 rounded-full bg-gray-500 mr-0.5'}></div>
                                            )
                                        }
                                    </div>
                                    <div className={'flex justify-between w-full'}>
                                        <div className={'flex flex-col py-3 border-b border-b-[#F7F7F7] w-full'}>
                                            <span className={'text-[14px] font-normal'}>{product!.title}</span>
                                            {!product.is_product_active ? '' :
                                                <span
                                                    className={'text-[16px] flex-col flex text-[#737373] font-medium'}>{product.price ? moneyFormat(product!.price) + ' ریال ' : ''}
                                                    {product.old_price ? (
                                                        <span className="text-[14px] text-[#DE0046] font-medium line-through ml-2">
                    {moneyFormat(product.old_price) + ' ریال'}
                </span>
                                                    ) : ''}
                            </span>}
                                            <div className={'px-2 w-full mt-[20px] whitespace-pre-wrap'}>
                                                <p>{product!.description != null ? product!.description :
                                                    <span>توضیحاتی ندارد.</span>}</p>
                                            </div>
                                            {/*    </div>*/}

                                            {/*}*/}
                                        </div>
                                        {/*<span*/}
                                        {/*    className="text-white text-xs font-bold rounded-lg bg-green-500 inline-block mt-4 ml-4 py-1.5 px-4 cursor-pointer">*/}
                                        {/*  {product.category.title}*/}
                                        {/*</span>*/}
                                        {/*            <h1 className="text-2xl mt-2 ml-4 font-bold text-gray-800 cursor-pointer hover:text-gray-900 transition duration-100">*/}
                                        {/*                {product.title}*/}
                                        {/*            </h1>*/}
                                        {/*            <p className="ml-4 mt-1 mb-2 text-gray-700 hover:underline cursor-pointer">*/}
                                        {/*                {product.description}*/}
                                        {/*            </p>*/}
                                    </div>

                                </div>
                                <div className={'w-full h-14 flex justify-center items-center'}>

                                    <div className={'w-full mt-[12px] mb-3'}>
                                        {
                                            user!.is_super_admin || !isMerchantFilled || !product.is_product_active ?

                                                <a href={`tel:${settings.find(e => e.name === 'phone_number')?.value}`}
                                                   className={'w-full cursor-pointer rounded-lg bg-[#4192EF] flex justify-center py-2 text-[14px] font-normal text-white '}>تماس با ما
                                                </a> :
                                                (product.zero_inventory && product.quantity == 0 ) ? <div
                                                                                                           className={'w-full cursor-pointer rounded-lg bg-[#DE0046] flex justify-center py-2 text-[14px] font-normal text-white '}>
                                                    موجودی به پایان رسیده
                                                </div> : <div onClick={() => changeCartValues(product!.id)}
                                                              className={'w-full cursor-pointer rounded-lg bg-[#4192EF] flex justify-center py-2 text-[14px] font-normal text-white '}>افزودن
                                                    به سبد
                                                    خرید
                                                </div>
                                        }
                                    </div>
                                </div>

                                <div className={'px-2 w-full md:hidden mt-[20px] whitespace-pre-wrap'}>
                                    <p>{product!.description != null ? product!.description :
                                        <span>توضیحاتی ندارد.</span>}</p>
                                </div>

                            </div>

                        </div>

                }
            </div>
            <div className={'py-3 w-full h-full md:hidden flex flex-col justify-center'}>
                {/*<div className={'w-full text-center bg-orange-200 fixed text-orange-500 bottom-0 right-0 left-0 rounded-xl'}>موجودی پست رو به پایان است</div>*/}

                <DeleteModal
                    title="حذف پست"
                    message="آیا از حذف این پست مطمئن هستید؟"
                    onSubmit={handleDeleteConfirm}
                    isOpen={isModalOpen}
                    handleIsOpen={setIsModalOpen}
                ></DeleteModal>
                <EditProductModal
                    product={editingItem}
                    onClose={(isOpen: boolean) => {
                        setEditingItem(null);
                    }}
                ></EditProductModal>

                <div
                    className={'cursor-pointer fixed top-0 z-50 bg-white py-2 w-full md:hidden flex justify-between items-center'}>
                    <div className={'flex'}>
                        <Link to={Routes.products.path}
                              className={'hover:bg-[#4192EF33]  rounded-full w-[44px] h-[43px] justify-center items-center flex ml-1.5'}><IconArrowRight/></Link>
                        <div className={'md:hidden flex'}>
                            {
                                shop!.avatar == null ? <img
                                        className="w-[42px] h-[42px] rounded-full"
                                        src={dLoad}
                                        alt={""}/> :

                                    <Link to={Routes.aboutUs.path} className="rounded-full">
                                        <div
                                            className={`rounded-full relative ${highLights! != null && highLights!.length != 0 ? "bg-gradient-to-b from-[#DE0046] to-[#F7A34B]" : "bg-white"} flex w-[47px] h-[47px] justify-center items-center `}>

                                            <div
                                                className='rounded-full relative bg-white flex w-[44px] h-[43px] justify-center items-center'>

                                                <NetworkImage className={'w-[42px] h-[42px] rounded-full'}
                                                              url={shop!.avatar}
                                                              alt={''}/>
                                            </div>
                                        </div>
                                    </Link>
                            }
                            <div className={'flex flex-col justify-start mr-2'}>
                                                <span
                                                    className={'text-[14px] '}>{settings.find(e => e.name === 'shop_name')?.value}</span>
                                <span
                                    className={'text-[12px] w-48 mt-0.5 ml-2'}>{shop!.description != null ? shop!.description.length > 30 ? shop!.description.substring(0, 30) + '...' : shop!.description : "بیوگرافی ندارد."}</span>
                            </div>
                        </div>
                    </div>
                    <div className={'flex items-start justify-end w-full mb-4 pl-4'}>

                        <div
                            className="md:hidden flex cursor-pointer w-4 h-4 transition-all duration-300">

                            {user!.id === shop!.id && (
                                <div className="rounded-lg">
                                    <div className="dropdown dropdown-left p-1 rounded-lg">
                                        <label tabIndex={0} className="bg-transparent cursor-pointer ">
                                            <MdOutlineMoreVert size={24} color="gray   "/>
                                        </label>
                                        <ul tabIndex={0}
                                            className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-44">
                                            <li
                                                onClick={() => {
                                                    setEditingItem(product);
                                                }}
                                                className="px-1 p-0 bg-transparent border-none cursor-pointer hover:bg-gray-400 hover:bg-opacity-50 rounded-lg items-start">
                                                <div
                                                    className="px-1 bg-transparent hover:bg-transparent">
                                                    <AiOutlineEdit
                                                        onClick={() => {
                                                            setEditingItem(product);
                                                        }
                                                        }
                                                        size={24}/>
                                                    ویرایش
                                                </div>
                                            </li>
                                            <li

                                                onClick={() => {
                                                    handleDeleteClick(product!);
                                                }}
                                                className="px-1 p-0 bg-transparent border-none cursor-pointer hover:bg-gray-400 hover:bg-opacity-50 rounded-lg items-start">
                                                <div
                                                    className="px-1 text-[#DE0046] bg-transparent hover:bg-transparent">
                                                    <AiOutlineDelete size={24}/>
                                                    حذف
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>)}
                        </div>
                    </div>
                </div>
                {
                    loading || product === null || product === undefined ? <div></div> :
                        <div
                            className={'md:flex md:h-full h-screen md:flex-row flex flex-col w-full mt-6 justify-start'}>
                            <div
                                className={'flex justify-between px-4 md:h-full items-start max-h-4/6 h-4/6 md:w-full w-full'}>
                                <div
                                    className="flex flex-col h-full items-start md:justify-start justify-end w-full mt-2">
                                    {product!.media.length == 0 ? <div className={'h-full '}>
                                            <img
                                                className="h-full w-full object-cover rounded-xl"
                                                src={dLoad}
                                                alt={""}/>
                                        </div> :
                                        <div className="carousel w-full h-full">
                                            {
                                                product.media
                                                    .map((e, i) => (
                                                        <div
                                                             className="carousel-item flex h-full relative w-full mr-2">
                                                            <div className="relative w-full h-full mt-4">
                                                                <img
                                                                    src={serverAsset(e.url)}
                                                                    alt=""
                                                                    //@ts-ignore
                                                                    onClick={() => document.getElementById('my_modal_2').showModal()}
                                                                    className="absolute object-contain w-full h-full transition duration-300 z-20 ease-in-out transform" // No blur here
                                                                />
                                                            </div>
                                                        </div>
                                                    ))
                                            }

                                        </div>
                                    }
                                </div>
                            </div>
                            <div className={'md:w-full md:mr-4 px-1 pb-6 md:min-h-4/6 flex flex-col justify-between '}>
                                <div>

                                    <div className={'md:flex hidden items-start justify-between mt-4'}>
                                        <div className={'flex'}>
                                            {
                                                shop!.avatar == null ? <img
                                                        className="w-[50px] h-[50px] rounded-full"
                                                        src={dLoad}
                                                        alt={""}/> :

                                                    <Link to={Routes.aboutUs.path} className="rounded-full">
                                                        <div
                                                            className={`rounded-full relative ${highLights! != null && highLights!.length != 0 ? "bg-gradient-to-b from-[#DE0046] to-[#F7A34B]" : "bg-white"} flex w-[47px] h-[47px] justify-center items-center `}>

                                                            <div
                                                                className='rounded-full relative bg-white flex w-[44px] h-[43px] justify-center items-center'>

                                                                <NetworkImage
                                                                    className={'w-[42px] h-[42px] rounded-full'}
                                                                    url={shop!.avatar}
                                                                    alt={''}/>
                                                            </div>
                                                        </div>
                                                    </Link>
                                            }

                                            <div className={'flex flex-col justify-start mr-2'}>
                                            <span
                                                className={'text-[14px] '}>{settings.find(e => e.name === 'shop_name')?.value}</span>
                                                <span
                                                    className={'text-[12px] mt-1 ml-2'}>{shop!.description != null ? shop!.description.length > 40 ? shop!.description.substring(0, 40) + '...' : shop!.description : "بیوگرافی ندارد."}</span>
                                            </div>
                                        </div>
                                        <div
                                            className="flex cursor-pointer w-4 h-4 transition-all duration-300">

                                            {user!.id === shop!.id && (
                                                <div className="rounded-lg">
                                                    <div className="dropdown dropdown-left p-1 rounded-lg">
                                                        <label tabIndex={0} className="bg-transparent cursor-pointer ">
                                                            <MdOutlineMoreHoriz size={24} color="gray   "/>
                                                        </label>
                                                        <ul tabIndex={0}
                                                            className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-44">
                                                            <li
                                                                onClick={() => {
                                                                    setEditingItem(product);
                                                                }}
                                                                className="px-1 p-0 bg-transparent border-none cursor-pointer hover:bg-gray-400 hover:bg-opacity-50 rounded-lg items-start">
                                                                <div
                                                                    className="px-1 bg-transparent hover:bg-transparent">
                                                                    <AiOutlineEdit
                                                                        onClick={() => {
                                                                            setEditingItem(product);
                                                                        }
                                                                        }
                                                                        size={24}/>
                                                                    ویرایش
                                                                </div>
                                                            </li>
                                                            <li

                                                                onClick={() => {
                                                                    handleDeleteClick(product);
                                                                }}
                                                                className="px-1 p-0 bg-transparent border-none cursor-pointer hover:bg-gray-400 hover:bg-opacity-50 rounded-lg items-start">
                                                                <div
                                                                    className="px-1 text-[#DE0046] bg-transparent hover:bg-transparent">
                                                                    <AiOutlineDelete size={24}/>
                                                                    حذف
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>)}
                                        </div>
                                    </div>

                                    <div className={
                                        'md:hidden flex w-full justify-center mt-6'
                                    }>
                                        {
                                            product!.media.map((media, e) =>
                                                <div className={'w-2 h-2 rounded-full bg-gray-500 mr-0.5'}></div>
                                            )
                                        }
                                    </div>
                                    <div className={'flex justify-between w-full px-4'}>
                                        <div className={'flex flex-col py-3 border-b border-b-[#F7F7F7] w-full'}>
                                            <div className={'flex w-full cursor-pointer justify-between'}>

                                                <span className={'text-[14px] font-normal'}>{product!.title}</span>
                                                <div>
                                                    {/*{*/}
                                                    {/*    isLike ? <div className="text-black" onClick={() => handleSetLike(product.id, !isLike)}>*/}
                                                    {/*        <IconHeart/>*/}
                                                    {/*    </div> : <div className="" onClick={() => handleSetLike(product.id, !isLike)}>*/}
                                                    {/*        <IconHeartFilled className={'text-[#DE0046]'} color={'#DE0046'}/>*/}
                                                    {/*    </div>*/}
                                                    {/*}*/}
                                                </div>
                                            </div>
                                            {
                                                !product.is_product_active ? '' :
                                                    <span
                                                        className={'text-[13px] font-normal mt-1 text-[#737373]'}> {product!.price} ریال  </span>
                                            }
                                            {/*<div className={''}>
                                                {
                                                    product?.attribute?.map((attribute, index) =>
                                                        <div key={index} className={'flex flex-col mt-4'}>
                                                            <span>{attribute.name}</span>
                                                            <div className={'flex carousel mt-1'}>
                                                                {
                                                                    attribute.attributeValues?.map((attrValue, i) => {
                                                                        const isSelected = selectedKey === attrValue.key;
                                                                        const isClickable = !selectedKey || isSelected;
                                                                        return (
                                                                            <div
                                                                                key={i}
                                                                                className={`py-1 mr-1 cursor-pointer px-3 text-sm border-[2px] ${isClickable ? 'border-red-gray-100' : 'border-gray-300'} rounded-full ${isSelected ? 'bg-blue-500 text-white' : ''}`}
                                                                                onClick={() => isClickable && handleAttributeClick(attrValue.key, attrValue.value)}
                                                                                style={{pointerEvents: isClickable ? 'auto' : 'none'}}
                                                                            >
                                                                                {attrValue.key}
                                                                            </div>
                                                                        );
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </div>*/}
                                            <div
                                                className={'px-2 w-full md:block hidden mt-[20px] whitespace-pre-wrap'}>
                                                <p>{product!.description != null ? product!.description :
                                                    <span>توضیحاتی ندارد.</span>}</p>
                                            </div>
                                            {/*    </div>*/}

                                            {/*}*/}
                                        </div>
                                    </div>

                                </div>
                                <div className={'w-full flex justify-center items-center px-4'}>

                                    <div className={'w-full mt-[12px] mb-3'}>
                                        {
                                            user!.is_super_admin || !isMerchantFilled || !product.is_product_active ?

                                                <a href={`tel:${settings.find(e => e.name === 'phone_number')?.value}`}
                                                   className={'w-full cursor-pointer rounded-lg bg-[#4192EF] flex justify-center py-2 text-[14px] font-normal text-white '}>تماس با ما
                                                </a> :
                                                (product.zero_inventory && product.quantity == 0 ) ? <div
                                                    className={'w-full cursor-pointer rounded-lg bg-[#DE0046] flex justify-center py-2 text-[14px] font-normal text-white '}>
                                                    موجودی به پایان رسیده
                                                </div> : <div onClick={() => changeCartValues(product!.id)}
                                                              className={'w-full cursor-pointer rounded-lg bg-[#4192EF] flex justify-center py-2 text-[14px] font-normal text-white '}>افزودن
                                                    به سبد
                                                    خرید
                                                </div>
                                        }
                                    </div>
                                </div>

                                <div className={'w-full bg-[#F7F7F7] h-2 my-5'}></div>

                                <div className={'px-2 w-full md:hidden mt-[20px] text-[14px] text-[#121212  ] whitespace-pre-wrap'}>
                                    <p>{product!.description != null ? product!.description :
                                        <span>توضیحاتی ندارد.</span>}</p>
                                </div>

                            </div>
                            <div className={'w-full bg-[#F7F7F7] h-4 my-5'}></div>

                            <div className={'flex flex-col mt-10 px-4'}>
                                <div className={'flex justify-between'}>
                                    <span className={'text-[#121212] text-[14px]'}> سایر پست ها</span>
                                    <div className={'flex items-start'}>
                                        <Link to={
                                            Routes.products.path}
                                              className={'text-[12px] mt-0.5 ml-2 text-[#4192EF]'}>مشاهده
                                            همه</Link>
                                        <IconArrowLeft className={'text-[#4192EF]'} size={'21'}/>
                                    </div>
                                </div>
                                <div className={'flex carousel mt-1'}>
                                    {products == null ?
                                        <div></div> : productsList.slice(0, 6).map(products => products!.slice(0, 6).map((product, index) => (
                                            <Link
                                                to={Routes.product(product.id).path}
                                                key={index}
                                                className={'mr-1 w-[80px] carousel-item h-[80px] bg-white'}
                                            >
                                                <div
                                                    className={'w-[80px] carousel-item h-[80px] bg-white ml-[8px]'}>
                                                    {
                                                        product.media.length == 0 ?
                                                            <img
                                                                className="w-[80px] h-[80px] bg-white object-cover text-[12px] justify-center items-center flex"
                                                                src={dLoad}
                                                                alt={""}/>

                                                            :
                                                            <NetworkImage
                                                                className="w-[80px] h-[80px] object-cover text-[12px] justify-center items-center flex"
                                                                url={product.media[0].thumbnail}
                                                                alt={product.title}/>
                                                    }
                                                    {/*<span className={'text-[#DE0046]'}>{e!.title}</span>*/}
                                                </div>
                                            </Link>
                                        )))
                                    }
                                </div>
                            </div>
                        </div>

                }
            </div>
        </div>
    );
};

export default ProductPageModal;
