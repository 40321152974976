import React from "react";
import ReactApexChart from "react-apexcharts";
import { useAppSelector } from "../../../redux/hooks";
import { RootState } from "../../../redux/store";

const ApexChart: React.FC = () => {
    const { products } = useAppSelector((state: RootState) => state.adminProducts);

    // محاسبه تعداد پست ها فعال و غیرفعال
    const productsActiveLength = products.filter(r => r.is_product_active).length;
    const productsNotActiveLength = products.filter(r => !r.is_product_active).length;

    // تنظیمات سری‌ها و گزینه‌ها
    const series = [productsActiveLength, productsNotActiveLength];
    const options = {
        chart: {
            width: 380,
            type: 'pie',
        },
        labels: ['فعال', 'غیرفعال'],
        colors: ['#4192EF', '#DE0046'], // سبز برای فعال و قرمز برای غیرفعال
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 200
                },
                legend: {
                    position: 'bottom'
                }
            }
        }],
        plotOptions: {
            pie: {
                dataLabels: {
                    style: {
                        fontFamily: 'iranyekan, sans-serif' // استفاده از فونت Iranyekan در لیبل‌ها
                    }
                }
            }
        },
        legend: {
            labels: {
                fontFamily: 'iranyekan, sans-serif' // استفاده از فونت Iranyekan در لیبل‌های لگند
            }
        }
    };

    return (
        <div className={'w-full flex justify-center'}>
            <div id="chart">
                <ReactApexChart
                    //@ts-ignore
                    options={options}
                    series={series}
                    type="pie"
                    width={380}
                />
            </div>
        </div>
    );
};

export default ApexChart;
