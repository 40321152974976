import React, {useEffect} from 'react';
import {BrowserRouter, Routes, useLocation} from "react-router-dom";
import 'App.css';
import 'index.css';
import RootComponents from "components/root/rootComponents";
import Index from "./pages/front";
import {useAppSelector} from "./redux/hooks";
import store, {RootState} from "./redux/store";
import {useDispatch} from "react-redux";
import {ThunkDispatch} from "@reduxjs/toolkit";
import {getUserInfo} from "./redux/user/user/userAction";
import {checkOnline} from "./redux/tools/checkOnline/checkOnlineAction";
import {AnalyticsMainPage} from "./pages/front/analytics/AnalyticsMainPage";
import Store from "./redux/store";
import localToken from "./services/savedData/localToken";
import logo from 'assets/img/coloop-logo-44-min.-copy.png'
import {WarehousingMainPage} from "./pages/front/warehousing/main";
function App() {

    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
    //check
    async function requestUserInfo() {
        dispatch(getUserInfo({isReturnCache: true}));
        dispatch(getUserInfo({isReturnCache: false}));
    }
    //check if url start with /analytics
    const isAnalytics = window.location.pathname.startsWith('/analytics');
    const isWarehousing = window.location.pathname.startsWith('/warehouse');
    React.useEffect(() => {
        if (!localToken.get()) {
            localToken.set(Math.floor(1000000000 + Math.random() * 9000000000).toString());
        }
        dispatch(checkOnline());
        requestUserInfo();
    } ,[]);
    const {loading, isInternetOnline, isServerOnline, status} = useAppSelector((state: RootState) => state.checkOnline);
    const {storage_full} = useAppSelector((state: RootState) => state.user);

    const user = useAppSelector((state: RootState) => state.user);
    // const location = useLocation();

    // useEffect(() => {
    //     window.scrollTo(0, 0);
    // }, [location]);

    return (
        storage_full ? <div className="w-full h-full flex justify-center items-center overflow-x-hidden">
                <div className="text-center">
                    <h1 className="text-2xl text-red-500">
                        فضای ذخیره سازی فروشگاه تمام شده
                    </h1>
                    <p>
                        فضای ذخیره سازی فروشگاه پر شده است لطفا پلن فروشگاه را ارتقاء دهید
                    </p>
                    <a href="https://coloop.ir" className="btn outline-none text-white bg-[#4192EF]">
                        خرید پلن
                    </a>
                </div>
            </div> :
            status === 405 ? <div className="w-full h-full flex justify-center items-center overflow-x-hidden">
                    <div className="text-center">
                        <h1 className="text-2xl text-red-500">
                            فروشگاه یافت نشد
                        </h1>
                        <p>
                            فروشگاه مورد نظر یافت نشد برای ثبت فروشگاه به آدرس زیر مراجعه کنید
                        </p>
                        <a href="https://coloop.ir" className="btn btn-primary">
                            ثبت فروشگاه
                        </a>
                    </div>
                </div> :
                !isInternetOnline ? <div className="w-full h-full flex justify-center items-center overflow-x-hidden">
                    <div className="text-center">
                        <h1 className="text-2xl text-red-500">
                            شما آفلاین هستید
                        </h1>
                        <p>اتصال اینترنت خود را بررسی کنید</p>

                    </div>
                </div> : !isServerOnline ? <div className="w-full h-full flex justify-center items-center overflow-x-hidden">
                    <div className="text-center">
                        <h1 className="text-2xl text-red-500">
                            سرور آفلاین است
                        </h1>
                        <p>
                            سرور برای انجام تعمیرات آفلاین است
                        </p>
                        <p>
                            کمی دیگر مجددا آنلاین خواهیم شد ...
                        </p>
                    </div>
                </div> : user.loading ? <div className="w-full h-[100vh] flex justify-center items-center overflow-x-hidden">
                    {
                        !loading &&
                        <img src={logo} alt="" className={'md:w-[20%] w-[20%] loading loading-infinity mb-6 ml-10 cursor-pointer'}/>

                    }


                </div> : isAnalytics ? <BrowserRouter
                        //@ts-ignore
                        forceRefresh={true}>
                        <AnalyticsMainPage/>
                    </BrowserRouter>
                    : isWarehousing ? <BrowserRouter
                        //@ts-ignore
                        forceRefresh={true}>
                        <WarehousingMainPage/>
                    </BrowserRouter> : <div className="w-full mx-auto md:pb-0">
                        <BrowserRouter
                            //@ts-ignore
                            forceRefresh={true}>
                            <Index/>
                            <RootComponents/>
                        </BrowserRouter>
                    </div>
    );
}

export default App;