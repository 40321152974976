import React, {useState} from "react";
import {useAppSelector} from "redux/hooks";
import {submit} from "redux/user/login/loginAction";
import {RootState} from "redux/store";
import {useDispatch} from "react-redux";
import {ThunkDispatch} from "@reduxjs/toolkit";
import {Formik, Form, FormikHelpers} from 'formik';
import DefaultModal from "../../../components/modal";
import {DefaultInput} from "../../../components/fieilds";
import {Link} from "react-router-dom";
import Routes from "../../../utils/routing/routes";
import {IconArrowRight} from "@tabler/icons-react";

interface values {
    mobileNumber: string;
    password: string;
    isReseller: boolean;
}

export const LoginModal: React.FC = () => {

    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

    const [isUsePassword, setIsUsePassword] = useState(false);

    //useSelector is a hook that allows you to extract data from the Redux store state, using a selector function.
    const {loading, mobileNumber} = useAppSelector((state: RootState) => state.login);

    const handleLogin = async (data: values) => {
        try {
            // console.log('mobileNumber', data)
            dispatch(submit({...data, isUsePassword}));
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <DefaultModal
            label={'loginModal'} id={'loginModal'} modalBoxClasses={'p-4 overflow-x-hidden px-3 z-[50]'}>
            <label htmlFor={"loginModal"}>
                <div className={'cursor-pointer fixed top-0 z-[40] py-3 bg-white w-full md:hidden flex'}>
                    <span><IconArrowRight/></span>
                    <span className={'mr-2'}>ورود</span>
                </div>
            </label>
            {/*<Link to={Routes.products.path}*/}
            {/*      className={'cursor-pointer fixed top-0 z-[40] py-3 bg-white w-full md:hidden flex'}>*/}
            {/*    <span><IconArrowRight/></span>*/}
            {/*    <span className={'mr-2'}>بازگشت</span>*/}
            {/*</Link>*/}
            <Formik
                initialValues={{
                    mobileNumber: mobileNumber,
                    password: '',
                    isReseller: false,
                }}
                onSubmit={
                    (values: values, {setSubmitting}: FormikHelpers<values>) => {
                        handleLogin(values);
                    }
                }

                //validation
                validate={(values: values) => {
                    const errors: any = {};
                    if (!values.mobileNumber) {
                        errors.mobileNumber = 'شماره موبایل را وارد کنید';
                    } else if (!/^[0][9][0-9]{9}$/.test(values.mobileNumber)) {
                        errors.mobileNumber = 'شماره موبایل معتبر نیست';
                    }else if (!/^[0-9۰-۹]{11}$/.test(values.mobileNumber)) {
                        errors.mobileNumber = 'شماره موبایل به انگلیسی وارد کنید';
                    }
                    if (!isUsePassword) {
                        if (!values.password) {
                            errors.password = 'رمز عبور را وارد کنید';
                        } else if (values.password.length < 8) {
                            errors.password = 'رمز عبور باید حداقل 8 کاراکتر باشد';
                        }
                    }
                    return errors;
                }}
            >
                <Form method="dialog" className={'z-44'} >
                    <div className='flex-col justify-start items-center w-full flex md:mt-6 mt-10'>


                        <DefaultInput name={'mobileNumber'} type={'text'} placeholder={'مثال : 09012345678'}
                                      label={'شماره موبایل'}/>
                        <div
                            className={`transition-max-height overflow-hidden w-full ${!isUsePassword ? 'max-h-28' : 'max-h-0'}`}>
                            <DefaultInput name={'password'} type={'password'} placeholder={'*****'}
                                          label={'رمز عبور'}/>
                        </div>
                        <div className="flex items-center mb-4 w-full">
                            <div className="flex items-center">
                                <input
                                    type="checkbox"
                                    checked={isUsePassword}
                                    onChange={(e) => setIsUsePassword(e.target.checked)}
                                    className="checkbox"
                                />
                            </div>
                            <label className="label mr-3">
                                <span className="label-text">ورود با کد یکبار مصرف</span>
                            </label>
                        </div>

                    </div>
                    {/*doesnt have account?*/}
                    <div className="flex justify-start items-start cursor-pointer">
                        <div className="text-gray-500">حساب کاربری ندارید؟  </div>
                        <div onClick={() => (document.querySelector('#registerModal') as HTMLInputElement).checked = true} className="text-blue-500 mx-1"> ثبت نام کنید</div>
                    </div>
                    <div className="modal-action w-full flex justify-start">
                        <button type="submit"
                                className={`btn hover:text-[#4192EF] w-wide text-white ${loading ? 'bg-gray-400' : "bg-[#4192EF] hover:scale-102 hover:shadow-soft-xs active:opacity-85 "}`}>
                            ورود
                        </button>
                    </div>
                </Form>
            </Formik>
        </DefaultModal>

    );
}

export default LoginModal;


