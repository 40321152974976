// services/apiConfig.ts

import Auth from "./savedData/auth";
import SavedData from "./savedData/savedData";
import api from "./api";



export const API_CONFIG = {
    headers: {
        "Accept": "application/json",
        'Content-Type': 'application/json; charset=UTF-8',
    },
};

export const serverAsset = (path: string) => {
    if (path?.startsWith('http'))
        return path;
    if (path?.startsWith('/')) path = path.substr(1);
    return `${process.env.REACT_APP_BASE_URL}/${path}`;
}

//logout
export const logout = () => {
    (new SavedData(`${process.env.REACT_APP_API_BASE_URL!}/users/get`)).del();
    Auth.del();
    window.location.reload();
}

export const getAbsAddress = (url: string) => {
    return process.env.REACT_APP_APP_URL + (url.startsWith('/') ? url : '/' + url);
}
