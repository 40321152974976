import React, {useEffect} from 'react';
import {DefaultInput, DefaultSelect, DefaultTextArea} from "../../../components/fieilds";
import {Form, Formik, FormikHelpers} from "formik";
import DefaultModal from "../../../components/modal";
import {submit, update} from "../../../redux/dashboard/categories/categoriesAction";
import {useDispatch} from "react-redux";
import {ThunkDispatch} from "@reduxjs/toolkit";
import {useAppSelector} from "../../../redux/hooks";
import {RootState} from "../../../redux/store";
import Category from "../../../models/category";
import {DefaultResponse} from "../../../redux/mainSlice";
import {IconArrowRight} from "@tabler/icons-react";


interface values {
    title: string;
    parentId: number | undefined;
}


const EditCategoryModal: React.FC<{ category: Category | null, onClose: (isOpen: boolean) => void }> = ({
                                                                                                            category,
                                                                                                            onClose
}) => {

    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
    const {submitLoading, categories} = useAppSelector((state: RootState) => state.adminCategories);

    //edit
    const handleEditConfirm = async (category: Category) => {
        // Todo: fix this error in the future
        const result: DefaultResponse = (await dispatch(update(category))).payload as DefaultResponse;
        if (result.status === 200 || result.status === 201) {
            onClose(true);
        }
    }

    return (
            <DefaultModal
                label={'editCategory'} id='editCategory' isOpen={!!category} handleIsOpen={onClose}>
                {category ? (
                    <Formik
                        initialValues={{
                            title: category.title || '',
                            parentId: category.parentId || undefined,
                        }}
                        onSubmit={(values: values, {setSubmitting}: FormikHelpers<values>) => {
                            try {
                                dispatch(
                                    handleEditConfirm({
                                        title: values.title,
                                        id: category!.id,
                                        parentId: values.parentId,
                                    })
                                );

                                (document.querySelector("[for='editCategory']") as HTMLElement).click();
                            } catch (error) {
                            } finally {
                                setSubmitting(false); // Make sure to set submitting to false
                            }
                        }}
                        //validation
                        validate={(values: values) => {
                            const errors: any = {};
                            if (!values.title) {
                                errors.name = 'نام پست را وارد کنید';
                            }
                            return errors;
                        }}>
                        <Form method="dialog">
                            <label htmlFor={'editCategory'}
                                   className={'cursor-pointer fixed top-0 z-[40]  px-2 py-3 bg-white w-full md:hidden flex'}>
                                <span><IconArrowRight/></span>
                                <span className={'mr-2'}>ویرایش</span>
                            </label>
                            <div className='flex-col justify-start items-center md:mt-4 mt-12 w-full flex'>

                                <DefaultInput
                                    label='نام دسته بندی'
                                    name="title"
                                    type="text"
                                    placeholder='نام دسته بندی'
                                ></DefaultInput>
                                <DefaultSelect
                                    label='انتخاب کنید'
                                    name="parentId"
                                    placeholder='انتخاب کنید'
                                    options={categories
                                        .filter(categor => categor.id !== category.id)
                                        .map(categor => ({
                                            label: categor.title,
                                            value: categor.id
                                        }))
                                    }
                                />

                            </div>
                            <div className="modal-action w-full flex justify-start">
                                <button type="submit"
                                        className={`btn text-white ${submitLoading ? 'bg-gray-400' : "bg-[#4192EF] hover:scale-102 hover:shadow-soft-xs active:opacity-85"}`}>
                                    ویرایش
                                </button>
                                <div className={'w-0.5 h-2'}></div>
                                <label
                                    htmlFor="editCategory"
                                    className="btn bg-[#DE0046] text-white hover:scale-102 hover:shadow-soft-xs active:opacity-85">بستن
                                </label>
                            </div>
                        </Form>
                    </Formik>
                ) : (<div></div>)}
            </DefaultModal>
    );
};

export default EditCategoryModal;
