import React from 'react';
import {DefaultInput, DefaultSelect} from "../../../components/fieilds";
import {Form, Formik, FormikHelpers} from "formik";
import DefaultModal from "../../../components/modal";
import {update} from "../../../redux/dashboard/discountCodes/discountCodesAction";
import {useDispatch} from "react-redux";
import {ThunkDispatch} from "@reduxjs/toolkit";
import {useAppSelector} from "../../../redux/hooks";
import {RootState} from "../../../redux/store";
import DiscountCode, {DiscountType} from "../../../models/discountCode";
import {DefaultResponse} from "../../../redux/mainSlice";
import {IconArrowRight} from "@tabler/icons-react";


interface values {
    name: string,
    code: string,
    type: DiscountType,
    amount: number,
    productId?: number,
    categoryId?: number,
    discountCode?: string,
    x?: any
}


const EditDiscountCodeModal: React.FC<{ discountCode: DiscountCode | null, onClose: (isOpen: boolean) => void }> = ({
                                                                                                            discountCode,
                                                                                                            onClose
}) => {

    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
    const {submitLoading, discountCodes} = useAppSelector((state: RootState) => state.adminDiscountCodes);

    const categories = useAppSelector((state: RootState) => state.adminCategories.categories);
    const products = useAppSelector((state: RootState) => state.adminProducts.products);

    //edit
    const handleEditConfirm = async (discountCode: DiscountCode) => {
        // Todo: fix this error in the future
        const result: DefaultResponse = (await dispatch(update(discountCode))).payload as DefaultResponse;
        if (result.status === 200 || result.status === 201) {
            onClose(true);
        }
    }

    return (
            <DefaultModal
                label={'editDiscountCode'}  id='editDiscountCode' isOpen={!!discountCode} handleIsOpen={onClose} modalBoxClasses={'px-3'}>
                {discountCode ? (
                    <Formik
                        initialValues={{
                            name: discountCode.name,
                            code: discountCode.code,
                            type: discountCode.type,
                            amount: discountCode.amount,
                            productId: discountCode.product?.id,
                            discountCode: discountCode.code,
                            categoryId: discountCode.category?.id,
                        }}
                        onSubmit={(values: values, {setSubmitting}: FormikHelpers<values>) => {
                            
                            try {
                                dispatch(
                                    handleEditConfirm({...values, id: discountCode.id})
                                );

                                (document.querySelector("[for='editDiscountCode']") as HTMLElement).click();
                            } catch (error) {
                            } finally {
                                setSubmitting(false); // Make sure to set submitting to false
                            }
                        }}
                        //validation
                        validate={(values: values) => {
                            const errors: any = {};

                            if (!values.name) {
                                errors.name = 'نام کد تخفیف  را وارد کنید';
                            }
                            if (!values.code) {
                                errors.code = 'کد تخفیف را وارد کنید';
                            }
                            if (!values.amount) {
                                errors.amount = 'مقدار تخفیف را وارد کنید';
                            }
                            if (values.categoryId && values.productId) {
                                errors.categoryId = 'لطفا پست یا دسته بندی را انتخاب کنید';
                            }
                            if (values.categoryId && values.productId) {
                                errors.productId = 'لطفا پست یا دسته بندی را انتخاب کنید';
                            }
                            return errors;

                        }}>
                        <Form method="dialog">
                            <label htmlFor={'editDiscountCode'}
                                   className={'cursor-pointer fixed top-0 z-[40]  px-2 py-3 bg-white w-full md:hidden flex'}>
                                <span><IconArrowRight/></span>
                                <span className={'mr-2'}>ویرایش</span>
                            </label>
                            <div className='flex-col justify-start items-center md:mt-4 mt-12 w-full flex'>

                                <DefaultInput name='name' label='نام' type='text' placeholder='نام'/>
                                <DefaultInput name='code' label='کد تخفیف' type='text' placeholder='کد تخفیف'/>
                                <DefaultSelect name='type' label='نوع تخفیف'
                                               options={[{
                                                   value: DiscountType.PERCENT,
                                                   label: 'درصدی'
                                               }, {value: DiscountType.FIXED, label: 'مقداری'}]}/>
                                <DefaultInput name='amount' label='مقدار' type='number' placeholder='مقدار'/>
                                {/*    products and categoris*/}


                                <DefaultSelect name='productId' label='پست'
                                               defaultValue={`${discountCode.product?.id}`}
                                               options={products.map((product) => ({
                                                   value: product.id,
                                                   label: product.title,
                                               })) as any}/>

                                <DefaultSelect name='categoryId' label='دسته بندی'
                                               defaultValue={`${discountCode.category?.id}`}
                                               options={categories.map((category) => ({
                                                   value: category.id,
                                                   label: category.title
                                               })) as any}/>
                            </div>
                            <div className="modal-action w-full flex justify-start">
                                <button type="submit"
                                        className={`btn text-white ${submitLoading ? 'bg-gray-400' : "bg-[#4192EF] hover:scale-102 hover:shadow-soft-xs active:opacity-85"}`}>
                                    {
                                        submitLoading ? 'درحال ویرایش' : 'ویرایش'
                                    }
                                </button>

                                <div className={'w-0.5 h-2'}></div>
                                <label
                                    htmlFor="editDiscountCode"
                                    className="btn bg-[#DE0046] text-white hover:scale-102 hover:shadow-soft-xs active:opacity-85">بستن
                                </label>
                            </div>
                        </Form>
                    </Formik>
                ) : (<div></div>)}
            </DefaultModal>
    );
};

export default EditDiscountCodeModal;
