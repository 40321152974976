import {createSlice} from "@reduxjs/toolkit";
import {DefaultStates} from "redux/mainSlice";
import Product from "../../../models/product";
import {LaravelValidationErrorType} from "../../../utils/types";
import {pagination, toastSuccess} from "../../../utils/funcions";
import {getAll, getProduct, remove, submit, update} from "./productsAction";
import store from "redux/store";
import {getProductsCategory} from "../categories/productsCategoryAction";
import Category from "../../../models/category";
import product from "../../../models/product";
import DiscountCode from "../../../models/discountCode";

interface State extends DefaultStates {
    products: Product[];
    productsList: Product[][];
    product: Product | null;
    paginate: number;
    lastPage: number;
    submitLoading: boolean;
    loadingMore: boolean;
    isEnd: boolean;
}

const initialState: State = {
    products: [],
    productsList: [],
    product: null,
    paginate: 1,
    lastPage: 1,
    submitLoading: false,
    loading: true,
    isEnd: false,
    validationErrors: null,
    loadingMore: false,
};

const ProductsSlice = createSlice({
    name: "products",
    initialState,
    reducers: {

        setPaginate: (state) => {
            state.paginate = state.paginate + 1; // Update loadingMore state
        },

        setPaginateToStart: (state) => {
            state.paginate = 1; // Update loadingMore state
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getAll.pending, (state) => {
            if (state.paginate === 1) {
                state.loading = true;
            }else {
                state.loadingMore = true;
            }
        })
            builder.addCase(getAll.fulfilled, (state, action) => {
                state.loading = false;
                state.loadingMore = false;
                const response = action.payload;
                if (response.status === 200) {
                    const products: Product[] = [];
                    for (let i = 0; i < response.data.data.length; i++) {

                        const product = response.data.data[i];
                        products.push(
                            new Product({
                                id: product.id,
                                title: product.title,
                                code: product.code,
                                category_id: product.category_id,
                                price: product.price,
                                old_price: product.old_price,
                                buy_price: product.buy_price,
                                category: product.category,
                                high_light: product.high_light,
                                highLightId: product.highLightId,
                                high_light_id: product.high_light_id,
                                description: product.description,
                                // attribute: product.attribute,
                                quantity: product.quantity,
                        media: product.media,
                            like: product.like,
                            is_product_active: product.is_product_active,
                                inventory_warning: product.inventory_warning,
                                zero_inventory: product.zero_inventory,
                        // property_id: product.property_id,
                    })
                )
                }
                if ( response.data.data.length === 0 || response.data.data.length < 30) {
                    state.isEnd = true
                    }
                    state.products = products
                    if (state.paginate === 1) {
                        state.productsList = [products];
                    }else {
                        // toastSuccess('svsresfvsrsg')
                        state.productsList.push(products);
                    }
                } else if (response.status === 422) {
                    state.validationErrors = response.data as LaravelValidationErrorType;
                }
            })
            builder.addCase(getAll.rejected, (state, action) => {

                state.loading = false;
            })
            .addCase("setLoadingMore/pending", (state) => {
                state.loadingMore = true; // Set loadingMore to true on infinite scroll trigger
            })
            .addCase("setLoadingMore/fulfilled", (state) => {
                state.loadingMore = false; // Set loadingMore to false after successful fetch
            });
        builder.addCase(submit.pending, (state) => {
            state.submitLoading = true
        })
        builder.addCase(submit.fulfilled, (state, action) => {
            state.submitLoading = false;
            const response = action.payload;
            if (response.status === 200 || response.status === 201) {
                const data = response.data.data;
                state.productsList.map((e) => e.unshift(new Product({...data, parentId: data.parent_id})));
                toastSuccess('پست با موفقیت اضافه شد');
            } else if (response.status === 422) {
                state.validationErrors = response.data as LaravelValidationErrorType;
            }
        })
        builder.addCase(submit.rejected, (state, action) => {
            state.submitLoading = false;
        })

        builder.addCase(getProduct.pending, (state) => {
            state.loading = true
        })
        builder.addCase(getProduct.fulfilled, (state, action) => {
            state.loading = false;
            const response = action.payload;
            if (response.status === 200) {
                const product = response.data;
                state.product = new Product({
                    id: product.id,
                    title: product.title,
                    code: product.code,
                    category_id: product.category_id,
                    price: product.price,
                    old_price: product.old_price,
                    category: product.category,
                    high_light: product.high_light,
                    highLightId: product.highLightId,
                    high_light_id: product.high_light_id,
                    description: product.description,
                    // attribute: product.attribute,
                    quantity: product.quantity,
                    media: product.media,
                    like: product.like,
                    is_product_active: product.is_product_active,
                    inventory_warning: product.inventory_warning,
                    buy_price: product.buy_price,
                    zero_inventory: product.zero_inventory,
                    // property_id: product.property_id,
                });
            } else if (response.status === 422) {
                state.validationErrors = response.data as LaravelValidationErrorType;
            }
        })
        builder.addCase(getProduct.rejected, (state, action) => {

                state.loading = false;
            }
        )
        builder.addCase(remove.pending, (state) => {
            // state.loading = true
        })
        builder.addCase(remove.fulfilled, (state, action) => {
            // state.loading = false;
            const response = action.payload;
            if (response.status === 200) {
                const index = state.products.findIndex(product => product.id === response.data);
                state.productsList.map((e) => e.splice(index, 1))
                toastSuccess('پست با موفقیت حذف شد');
            } else if (response.status === 422) {
                state.validationErrors = response.data as LaravelValidationErrorType;
            }
        })
        builder.addCase(remove.rejected, (state, action) => {
                // state.loading = false;
            }
        )

//update
        builder.addCase(update.pending, (state) => {
            state.submitLoading = true
        })
        builder.addCase(update.fulfilled, (state, action) => {
                state.submitLoading = false;
                const response = action.payload;
                if (response.status === 200) {
                    toastSuccess('پست با موفقیت ویرایش شد');

                    state.productsList.forEach((contactList) => {
                        const index = contactList.findIndex(contact => contact.id === response.data.id);
                        contactList[index] = new Product(response.data);
                    })
                    // const index = state.products.findIndex(product => product.id === response.data.id);
                    // state.products[index] = new Product({
                    //     id: response.data.id,
                    //     title: response.data.title,
                    //     code: response.data.code,
                    //     category_id: response.data.category_id,
                    //     price: response.data.price,
                    //     category: response.data.category,
                    //     high_light_id: response.data.high_light_id,
                    //     description: response.data.description,
                    //     quantity: response.data.quantity,
                    //     cover: response.data.cover,
                    //     attribute: response.data.attribute,
                        // media: response.data.media,
                        // is_product_active: response.data.is_product_active,
                    // });
                } else if (response.status === 422) {
                    state.validationErrors = response.data as LaravelValidationErrorType;
                }
            }
        )
        builder.addCase(update.rejected, (state, action) => {
                state.submitLoading = false;
            }
        )
    },
});

export const {setPaginate, setPaginateToStart} = ProductsSlice.actions;
export default ProductsSlice.reducer;