import {Link, useLocation} from "react-router-dom";
import EditProductModal from './edit';
import React, {useEffect, useState} from 'react';
import store, {RootState} from "redux/store";
import {useAppSelector} from "redux/hooks";
import Product from "../../../models/product";
import {ThunkDispatch} from "@reduxjs/toolkit";
import {useDispatch, useSelector} from "react-redux";
import {DeleteModal} from "../../../components/modal";
import {DefaultResponse} from "../../../redux/mainSlice";
import {serverAsset} from "../../../services/connectionConfig";
import {MdAddShoppingCart, MdOutlineMoreHoriz} from "react-icons/md";

import {
    AiOutlineDelete,
    AiOutlineEdit,
} from "react-icons/ai";
import Routes from "../../../utils/routing/routes";
import {addToCart} from "../../../redux/dashboard/cart/addCartAction";
import {BsEye} from "react-icons/bs";
import {getAll, remove} from "../../../redux/dashboard/products/productsAction";
import {checkUser, getSettingByKey, moneyFormat, NetworkImage, pagination} from "../../../utils/funcions";
import AppBar from "../partials/appBar";
import dLoad from "../../../assets/img/cantLoadImage.webp";
import empty from "../../../assets/img/No data-pana-min.png";
import {
    IconHeart,
    IconHeartFilled,
    IconMessageChatbot,
    IconPhoneCall,
    IconShoppingCart,
    IconShoppingCartPlus
} from "@tabler/icons-react";
import {LoadingTableShimmer} from "../../../components/basincComponents";
import {setPaginate, setPaginateToStart} from "../../../redux/dashboard/products/productsSlice";
import {submitLike} from "../../../redux/dashboard/like/likeAction";
import {AsyncImage} from "loadable-image";
import {getHighlights} from "../../../redux/dashboard/highLights/highLightsAction";
// import {post} from "axios";


const ProductsList = () => {
    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
    const {productsList, loading} = useSelector(
        // @ts-ignore
        (state) => state.adminProducts
    );
    const highLights = useAppSelector((state: RootState) => state.highlights);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState<Product | null>(null);
    const [editingItem, setEditingItem] = useState<Product | null>(null);
    const {shop, settings, user, isMerchantFilled} = useAppSelector((state: RootState) => state.user);
    const {like} = useAppSelector((state: RootState) => state.like);

    // const {pathname} = location;

    const handleDeleteClick = (product: Product) => {
        setSelectedItem(product);
        setIsModalOpen(true);
    };

    const handleDeleteConfirm = async () => {
        if (selectedItem) {
            // setDeletedId(selectedItem.id);
            setSelectedItem(null);
            setIsModalOpen(false);
            const result: DefaultResponse = (await dispatch(remove(selectedItem.id))).payload as DefaultResponse;
            if (result.status !== 200 && result.status !== 201) {
                // setDeletedId(0);
            }
        }
    };


    const {submitLoading} = useSelector((state: RootState) => state.cart);
    const changeCartValues = (id: number) => {
        if (submitLoading) return;
        dispatch(addToCart({
            quantity: 1,
            productId: id
        }));
    }

    const adminProductsState = store.getState().adminProducts;
    useEffect(() => {
        const handlePagination = () => {
            if (!store.getState().adminProducts.loading && !adminProductsState.loadingMore && !store.getState().adminProducts.isEnd) {
                dispatch(setPaginate());
                dispatch(getAll({isReturnCache: false}));
            }
        };

        const cleanup = pagination(handlePagination);
        // if (store.getState().adminProducts.isEnd) {
        //     dispatch(setPaginate());
        // }
        return () => cleanup();
    })
    useEffect(() => {
        setPaginateToStart();
    }, []);
    useEffect(() => {
        dispatch(getHighlights())
    }, [dispatch]);

    return (
        <div>

            <DeleteModal
                title="حذف پست"
                message="آیا از حذف این پست مطمئن هستید؟"
                onSubmit={handleDeleteConfirm}
                isOpen={isModalOpen}
                handleIsOpen={setIsModalOpen}
            ></DeleteModal>
            <EditProductModal
                product={editingItem}
                onClose={(isOpen: boolean) => {
                    setEditingItem(null);
                }}
            ></EditProductModal>
            <div className='w-full flex justify-center'>

                <div
                    className={`flex flex-wrap pb-1.5 w-full mb-12`}>
                    {
                        loading ? <LoadingTableShimmer/> :
                            productsList.flat().length === 0 ?
                                <div className={'w-full flex flex-col justify-center items-center'}>
                                    <img src={empty} alt={""}/>
                                    <span className={'text-[#9E9E9E] text-[14px] mt-2'}>پستی یافت نشد.</span>
                                </div> :
                                // @ts-ignore
                                productsList.map(products => products!.map((product) =>
                                        <div className="group aspect-auto relative w-full  ">
                                            <div className={'flex items-start justify-between w-full mt-2 pl-4'}>
                                                <div className={'flex'}>
                                                    {
                                                        shop!.avatar == null ? <img
                                                                className="w-[50px] h-[50px] rounded-full"
                                                                src={dLoad}
                                                                alt={""}/> :
                                                            <Link to={Routes.aboutUs.path} className="rounded-full">
                                                                <div
                                                                    className={`rounded-full relative ${highLights.highLights != null && highLights.highLights!.length != 0 ? "bg-gradient-to-b from-[#DE0046] to-[#F7A34B]" : "bg-white"} flex w-[50px] h-[50px] justify-center items-center `}>

                                                                    <div
                                                                        className='rounded-full relative bg-white flex w-[47px] h-[46px] justify-center items-center'>

                                                                        <NetworkImage
                                                                            data-te-img="https://mdbcdn.b-cdn.net/img/Photos/Slides/2.webp"
                                                                            alt=""
                                                                            className="object-cover flex justify-center items-center w-[45px] h-[45px] data-[te-lightbox-disabled]:cursor-auto rounded-full"
                                                                            url={
                                                                                shop!.avatar
                                                                            }/>
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                    }

                                                    <div className={'flex flex-col justify-start mr-2'}>
                                                <span
                                                    className={'text-[14px] '}>{settings.find(e => e.name === 'shop_name')?.value}</span>
                                                        <span
                                                            className={'text-[12px] mt-1 ml-2'}>{shop!.description != null ? shop!.description.length > 40 ? shop!.description.substring(0, 40) + '...' : shop!.description : "بیوگرافی ندارد."}</span>
                                                    </div>

                                                </div>
                                                <div
                                                    className="flex cursor-pointer w-4 h-4 transition-all duration-300">

                                                    {user!.id === shop!.id && (
                                                        <div className="rounded-lg">
                                                            <div className="dropdown dropdown-left p-1 rounded-lg">
                                                                <label tabIndex={0}
                                                                       className="bg-transparent cursor-pointer ">
                                                                    <MdOutlineMoreHoriz size={24} color="gray   "/>
                                                                </label>
                                                                <ul tabIndex={0}
                                                                    className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-44">
                                                                    <li
                                                                        onClick={() => {
                                                                            setEditingItem(product);
                                                                        }}
                                                                        className="px-1 p-0 bg-transparent border-none cursor-pointer hover:bg-gray-400 hover:bg-opacity-50 rounded-lg items-start">
                                                                        <div
                                                                            className="px-1 bg-transparent hover:bg-transparent">
                                                                            <AiOutlineEdit
                                                                                onClick={() => {
                                                                                    setEditingItem(product);
                                                                                }
                                                                                }
                                                                                size={24}/>
                                                                            ویرایش
                                                                        </div>
                                                                    </li>
                                                                    <li

                                                                        onClick={() => {
                                                                            handleDeleteClick(product);
                                                                        }}
                                                                        className="px-1 p-0 bg-transparent border-none cursor-pointer hover:bg-gray-400 hover:bg-opacity-50 rounded-lg items-start">
                                                                        <div
                                                                            className="px-1 text-[#DE0000] bg-transparent hover:bg-transparent">
                                                                            <AiOutlineDelete size={24}/>
                                                                            حذف
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>)}
                                                </div>
                                            </div>
                                            {/*title on bottom of product*/}

                                            <div className={'h-[56%] flex items-start mt-2'}>
                                                {
                                                    product.media.length == 0 ? <img
                                                            className="w-full h-full border-none object-cover"
                                                            src={dLoad}
                                                            alt={""}/> :

                                                        <div className="carousel w-full h-full">
                                                            {
                                                                product.media
                                                                    .map((e: { thumbnail: string; }, i: any) =>
                                                                        <div // برای اینکه آیدی به درستی تنظیم شود
                                                                             className="carousel-item flex justify-start h-full border-none shadow-none relative w-full mr-2">
                                                                            <div
                                                                                className="relative w-full flex justify-center shadow-none">
                                                                                <img
                                                                                    src={serverAsset(e.thumbnail)}
                                                                                    alt={product.title}
                                                                                    className="object-contain md:w-full border-none shadow-none h-full items-center"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    )
                                                            }

                                                        </div>
                                                }
                                            </div>
                                            <div className={'mt-6 mb-4'}>
                                <span>

                                <div className={
                                    'md:hidden flex w-full justify-center mt-2'
                                }>
                                    {
                                        //@ts-ignore
                                        product.media.map((media, e) =>
                                            <div className={'w-2 h-2 rounded-full bg-gray-500 mr-0.5'}></div>
                                        )
                                    }
                                </div>
                    <div className={'flex flex-col'}>
                        <p className={'text-[16px] font-bold'}>{product.title}</p>
                        {!product.is_product_active ? '' :
                            <span
                                className={'text-[16px] text-[#737373] font-medium'}>{product.price ? moneyFormat(product!.price) + ' ریال ' : ''}</span>}
                        <p className={'mt-1 text-[13px] font-md h-max w-full whitespace-pre-wrap'}>{product.description != null ? product.description :
                            <span>توضیحاتی ندارد.</span>}</p>
                    </div>
                            </span>
                                                <div className={'mt-[12px] w-full flex justify-end'}>
                                                    {
                                                        user!.is_super_admin || !isMerchantFilled || !product.is_product_active || !product.price ?

                                                            <a href={`tel:${settings.find(e => e.name === 'phone_number')?.value}`}>

                                                                <div
                                                                    className={'btn btn-guest cursor-pointer md:ml-2 ml-6 border-none rounded-lg bg-[#4192EF] flex justify-center py-2 text-[14px] font-normal text-white '}>
                                                                    <IconPhoneCall/>
                                                                    <span className={'mr-2'}>
                                        تماس با ما
                                            </span>
                                                                </div>
                                                            </a> :

                                                            // <div onClick={() => changeCartValues(product.id)} className="group button-ripple flex flex-1 py-2 duration-300 justify-center items-center hover:bg-[#4192EF33] bg-[#4192EF] rounded-lg cursor-pointer border border-[#9999999] text-[14px]">
                                                            //     <span className="group-hover:text-[#4192EF] text-white flex text-[14px]">
                                                            //         <IconMessageChatbot className="groups-hover:w-10 w-0 duration-300"/>
                                                            //         افزودن به سبد خرید
                                                            //     </span>
                                                            // </div>
                                                        <div onClick={() => changeCartValues(product.id)} className={'flex ml-2  cursor-pointer px-6 py-3 rounded-lg hover:bg-[#4192EF33] bg-[#4192EF] duration-300 text-white hover:text-[#4192EF]'}>
                                                            <IconShoppingCartPlus className={'ml-2'}/>
                                                            افزودن به سبد خرید
                                                        </div>

                                                        //            <div onClick={() => changeCartValues(product.id)}
                                                        //                 className={'py-3 px-6 duration-300 w-full cursor-pointer md:ml-2 ml-3 border-none rounded-lg hover:bg-[#4192EF33] bg-[#4192EF] hover:text[#4192EF] flex justify-center text-[14px] font-normal text-white '}>
                                                        //                <IconShoppingCartPlus/>
                                                        //                <span className={'mr-2'}>
                                                        // افزودن به سبد خرید
                                                        //    </span>
                                                        //            </div>
                                                    }
                                                </div>
                                                <div>
                                                    {/*{isLike ? (*/}
                                                    {/*    <div*/}
                                                    {/*        className="text-[#DE0000]"*/}
                                                    {/*        onClick={() => handleSetLike(product.id, false, product)}*/}
                                                    {/*    >*/}
                                                    {/*        <IconHeartFilled />*/}
                                                    {/*    </div>*/}
                                                    {/*) : (*/}
                                                    {/*    <div*/}
                                                    {/*        className="text-black"*/}
                                                    {/*        onClick={() => handleSetLike(product.id, true, product)}*/}
                                                    {/*    >*/}
                                                    {/*        <IconHeart />*/}
                                                    {/*    </div>*/}
                                                    {/*)}*/}

                                                </div>
                                            </div>
                                            <hr/>
                                        </div>
                                ))
                    }
                </div>
            </div>
            {/*{products.length == 0 ?<div className={'w-full justify-around flex'}>*/}
            {/*    <div className="join fixed bottom-20 md:bottom-2 w-full z-40 justify-center">*/}
            {/*        {buttons}*/}
            {/*    </div>*/}
            {/*</div> : <div></div>}*/}
        </div>

    );
};

export default ProductsList;