import React, {useEffect, useState} from 'react';
import DefaultModal from "../../../components/modal";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../redux/store";
import {moneyFormat, NetworkImage} from "../../../utils/funcions";
import {ThunkDispatch} from "@reduxjs/toolkit";
import {changeCart, checkDiscountCart, checkoutCart, getAll as getCart} from "../../../redux/dashboard/cart/cartAction";
import {removeDiscount, setCartQuantity} from "../../../redux/dashboard/cart/cartSlice";
import {parseNumber} from "react-advanced-cropper";
import {IconArrowRight} from "@tabler/icons-react";
import dLoad from "../../../assets/img/cantLoadImage.webp";
import emptyr from "../../../assets/img/No data-pana-min.png";
import {useAppSelector} from "../../../redux/hooks";
import logo from "../../../assets/img/No data-pana-min.png";

const CartModal = () => {
    const [codeCheck, setCodeCheck] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [address, setAddress] = useState<string>('');

    const onClose = () => {
        //await to close the modal then go back
        setIsOpen(false);
        setTimeout(() => {
            window.history.back();
        }, 300);
    }

    const [isOpen, setIsOpen] = React.useState(false);
    let totalPriceCount: number = 0;

    React.useEffect(() => {
        setIsOpen(true);
    }, []);

        const {cart, loading, priceFromServer} = useSelector((state: RootState) => state.cart);
    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

    const changeCartItemNewQuantityValue = (id: number, quantity: number) => {
        dispatch(setCartQuantity({
            productId: id,
            quantity: quantity
        }));
    }
    const changeCartValues = (id: number, quantity: number) => {
        if (submitLoading) return;

        changeCartItemNewQuantityValue(id, quantity);
        dispatch(changeCart({
            quantity: quantity,
            productId: id
        }));
    }
    const checkoutCartValues = () => {
        checkoutCart(
            {code: codeCheck, description: description, address: address}
        )
    }
    const checkDiscountCartValues = () => {
        dispatch(checkDiscountCart({
            code: codeCheck,
            description: description,
            address: address,
        }))
    }

    const getQuantityByProductId = (id: number) => {
        return cart!.items!.find((item) => item.product.id === id)?.quantity ?? 0;
    }

    const increaseQuantity = (id: number) => {
        changeCartValues(id, getQuantityByProductId(id) + 1)
    }
    const decreaseQuantity = (id: number, quantity?: number) => {
        changeCartValues(id, quantity ?? getQuantityByProductId(id) - 1)
    }
    const {user, shop} = useAppSelector((state: RootState) => state.user);

    function removeDiscountCode() {
        dispatch(removeDiscount());
        setCodeCheck('');
    }

    useEffect(() => {
        dispatch(getCart());
    }, [dispatch]);
    const {settings} = useSelector((state: RootState) => state.user);

    const [submitLoading] = useState(false);

    console.log('console.log', loading, 'cart', cart)

    return (
        <DefaultModal
            label={'cartModal'} id='cartModal'
            modalBoxClasses="z-40 transition overflow-x-hidden px-4 ease-in-out duration-300 max-w-7xl md:!min-w-[500px] sm:!min-w-[320px]"
            isOpen={isOpen} handleIsOpen={onClose}>

            <label htmlFor={'cartModal'}
                   className={'cursor-pointer sticky -top-6 z-[40] px-2 py-3 bg-white w-full md:hidden flex'}>
                <span><IconArrowRight/></span>
                <span className={'mr-2'}>سبد خرید</span>
            </label>
            <div className=" mx-auto mb-40 z-40">

                {loading || cart == null ? (
                    <div
                        className={'px-4 py-6 md:hidden grid lg:gap-3 md:gap-2 mt-6 gap-1 lg:grid-cols-3 md:grid-cols-2 grid-cols-1'}>
                        <div className="flex flex-col gap-4">
                            <div className="flex flex-row-reverse justify-end gap-4 items-center">
                                {/*<div className="flex flex-col gap-4">*/}
                                {/*    <div className="skeleton h-4 w-20"></div>*/}
                                {/*    <div className="skeleton h-4 w-28"></div>*/}
                                {/*</div>*/}
                                {/*<div className="skeleton w-16 h-16 rounded-full shrink-0"></div>*/}
                            </div>
                            <div className="skeleton h-32 w-full"></div>
                        </div>
                        <div className="flex flex-col gap-4">
                            <div className="flex flex-row-reverse justify-end gap-4 items-center">
                                {/*<div className="flex flex-col gap-4">*/}
                                {/*    <div className="skeleton h-4 w-20"></div>*/}
                                {/*    <div className="skeleton h-4 w-28"></div>*/}
                                {/*</div>*/}
                                {/*<div className="skeleton w-16 h-16 rounded-full shrink-0"></div>*/}
                            </div>
                            <div className="skeleton h-32 w-full"></div>
                        </div>


                    </div>
                ) :
                    //@ts-ignore
                    cart.items.length == 0 ?  <div className={'w-full flex flex-col justify-center items-center'}>
                    <img src={logo} alt="" className={'w-[30%]'}/>
                    <span className={'text-lg'}>سبد خرید خالی میباشد</span>
                </div> :
                    <div className="md:flex-row flex flex-col w-full overflow-y-auto">

                        <div className="md:w-3/4 md:block hidden w-full md:px-10 px-0 my-10">
                            <div className="flex justify-between border-b pb-8">
                                <h1 className="font-semibold text-2xl">سبد خرید</h1>
                                <h2 className="font-semibold text-2xl">{cart.items!.length} پست</h2>
                            </div>

                            <div className="overflow-x-auto">
                                <table className="table">
                                    {/* head */}
                                    <thead>
                                    <tr>
                                        <th>پست</th>
                                        <th>تعداد</th>
                                        <th>قیمت واحد</th>
                                        <th>قیمت کل</th>
                                    </tr>
                                    </thead>
                                    <tbody>

                                    {cart.items!.map((item, index) => {
                                        totalPriceCount = totalPriceCount + (parseNumber(item.price) * parseNumber(item.quantity));
                                        return (
                                            <tr>
                                                <td>
                                                    <div className="flex items-start">
                                                        <div className="avatar">
                                                            <div className="mask mask-squircle w-20 h-20">
                                                                <NetworkImage
                                                                    className="h-24"
                                                                    url={item.product.media[0] === undefined || item.product.media[0] === null ? dLoad : item.product.media[0].thumbnail}
                                                                    alt={item.product.title}/>
                                                            </div>
                                                        </div>
                                                        <div className="mr-3">
                                                            <div className="font-bold">{item.product.title}</div>
                                                            <div
                                                                onClick={() => decreaseQuantity(item.product.id, 0)}
                                                                className="btn hover:text-[#DE0046] btn-ghost btn-xs text-[#DE0046]">
                                                                حذف
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="join">
                                                        <button onClick={() => decreaseQuantity(item.product.id)}
                                                                className="btn btn-sm join-item">
                                                            <svg
                                                                className="fill-current text-gray-600 w-3 cursor-pointer"
                                                                viewBox="0 0 448 512">
                                                                <path
                                                                    d="M416 208H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h384c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"/>
                                                            </svg>
                                                        </button>
                                                        <button className="btn px-0 btn-sm join-item">
                                                            <input
                                                                onChange={(e) => {
                                                                    changeCartItemNewQuantityValue(item.product.id, parseInt(e.target.value));
                                                                }}
                                                                className="border text-center w-8"
                                                                type="text"
                                                                value={item.quantity}
                                                            /></button>
                                                        <button onClick={() => increaseQuantity(item.product.id)}
                                                                className="btn btn-sm join-item">
                                                            <svg
                                                                className="fill-current text-gray-600 w-3 cursor-pointer"
                                                                viewBox="0 0 448 512">
                                                                <path
                                                                    d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"/>
                                                            </svg>
                                                        </button>
                                                    </div>
                                                </td>
                                                <td>{moneyFormat(item.price * 1, true)}</td>
                                                <td>{moneyFormat(item.price * item.quantity, true)}</td>
                                            </tr>
                                        );
                                    })}
                                    </tbody>
                                    {/* foot */}

                                </table>
                            </div>

                        </div>

                        <div className={'md:hidden mt-6'}></div>
                        {cart.items!.map((item, index) => {
                            totalPriceCount = totalPriceCount + (parseNumber(item.price) * parseNumber(item.quantity));
                            return (
                                <div
                                    className='flex md:hidden mt-4 items-start justify-start py-2 border-b border-b-[#F7F7F7]'>

                                    <div className="avatar flex flex-col justify-between">
                                        <div className="mask mask-squircle w-[58px] h-[58px]">
                                            <NetworkImage
                                                className="h-[48px]"
                                                url={item.product.media[0] === undefined || item.product.media[0] === null ? dLoad : item.product.media[0].thumbnail}
                                                alt={item.product.title}/>
                                        </div>

                                        <div
                                            className={'flex px-4 w-[58px] h-6  justify-center items-center rounded-lg mt-1 bg-[#DE004633]'}>
                                            <span
                                                className={'text-sm text-[#DE0044] cursor-pointer font-bold'}
                                                onClick={() => decreaseQuantity(item.product.id, 0)}>
                                                حذف
                                            </span>
                                        </div>

                                    </div>
                                    <div className='flex justify-between items-start pr-4 w-full'>
                                        <div className={'flex flex-col'}>
                                            <span
                                                className='text-[#121212] text-[14px] font-normal'>{item.product.title}</span>
                                            <span
                                                className='text-[#737373] text-[12px]'>{moneyFormat(item.product.price)} ریال</span>
                                        </div>

                                        <div className="join flex flex-col border border-1">

                                            <button onClick={() => increaseQuantity(item.product.id)}
                                                    className="md:btn btn-sm join-item">
                                                <svg
                                                    className="fill-current text-gray-600 w-2 cursor-pointer"
                                                    viewBox="0 0 448 512">
                                                    <path
                                                        d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"/>
                                                </svg>
                                            </button>

                                            <button className="px-0 btn-sm join-item">
                                                <input
                                                    onChange={(e) => {
                                                        changeCartItemNewQuantityValue(item.product.id, parseInt(e.target.value));
                                                    }}
                                                    className="text-center w-6"
                                                    type="text"
                                                    value={item.quantity}
                                                /></button>

                                            <button onClick={() => decreaseQuantity(item.product.id)}
                                                    className="md:btn btn-sm join-item">
                                                <svg
                                                    className="fill-current text-gray-600 w-2 cursor-pointer"
                                                    viewBox="0 0 448 512">
                                                    <path
                                                        d="M416 208H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h384c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"/>
                                                </svg>
                                            </button>

                                        </div>
                                    </div>
                                </div>
                            )
                        })}

                        <div id="summary" className="w-1/4 px-8 my-10 hidden md:block">
                            <h1 className="font-semibold text-2xl pb-8">خلاصه سفارش</h1>
                            {cart.items!.map((item, index) =>
                                <div className="flex justify-between mt-10 mb-5">
                                    <span className="font-semibold text-sm uppercase">{item.product.title}</span>
                                    {}
                                    <span
                                        className="font-semibold text-sm">{moneyFormat(item.price * item.quantity, true)}</span>
                                </div>
                            )}

                            <div className="mt-1.5 flex font-semibold justify-between text-sm uppercase">
                                <span className={'text-[#737373] text-[12px]'}>هزینه ارسال</span>
                                <span className={'text-[#737373] text-[12px]'}>{settings.map((e) => <div
                                    className={'flex flex-col'}>{e.name == "shipping_cost" ? <div>{e.value}ریال</div> :
                                    <div></div>}</div>)}</span>
                            </div>

                            <div className="form-control w-full max-w-xs mt-4">
                                <label className="label">
                                    <span className="label-text">کد تخفیف</span>
                                </label>
                                <div className="join">
                                    <input
                                        value={codeCheck}
                                        onChange={(value) => setCodeCheck(value.target.value)}
                                        placeholder="کد تخفیف"
                                        className="input input-bordered join-item w-full md:rounded-r-lg"/>
                                    {priceFromServer ? <button onClick={() => removeDiscountCode()}
                                                               className="btn join-item rounded-r-full text-white btn-error">حذف
                                    </button> : <button onClick={() => checkDiscountCartValues()}
                                                        className="btn hover:text-[#4192EF] join-item md:rounded-l-lg">اعمال
                                    </button>}
                                </div>
                            </div>
                            <div className="form-control w-full max-w-xs mt-4">
                                <label className="label">
                                    <span className="label-text">نشانی محل سکونت</span>
                                </label>
                                <div className="join">
                                    <input
                                        value={description}
                                        onChange={(value) => setDescription(value.target.value)}
                                        placeholder="آدرس"
                                        className="input input-bordered join-item w-full"/>
                                </div>
                            </div>
                            <div className="form-control w-full max-w-xs mt-4">
                                <label className="label">
                                    <span className="label-text">توضیحات </span>
                                </label>
                                <div className="join">
                                    <input
                                        value={address}
                                        onChange={(value) => setAddress(value.target.value)}
                                        placeholder="توضیحات"
                                        className="input input-bordered join-item w-full"/>
                                </div>
                            </div>
                            <div className="border-t">
                                <div className="flex font-semibold justify-between pt-6 py-2 text-sm uppercase">
                                    <span className={'text-[#737373] text-[12px]'}>جمع پست ها</span>
                                    <span
                                        className={'text-[#737373] text-[12px]'}>{moneyFormat(priceFromServer ?? ((totalPriceCount / 2)), true)}</span>
                                </div>
                                <div className="flex font-semibold justify-between py-2 text-sm uppercase">
                                    <span className={'text-[#737373] text-[12px]'}>هزینه ارسال</span>
                                    <span
                                        className={'text-[#737373] text-[12px]'}>{settings.find(e => e.name === 'shipping_cost')?.value}ریال</span>
                                </div>
                                {
                                    user!.id === 0 || !user || user == null || user == undefined ?
                                        <div onClick={() => window.history.back()}>
                                            <label htmlFor={'loginModal'}
                                                className="mt-2 btn hover:text-[#4192EF] bg-[#4192EF] text-white w-full"
                                            >
                                                ابتدا وارد شوید
                                            </label>
                                        </div>
                                        : <button onClick={() => checkoutCartValues()}
                                                  className="mt-2 btn hover:text-[#4192EF] bg-[#4192EF] text-white w-full">
                                            پرداخت
                                        </button>
                                }
                            </div>
                        </div>

                        {cart.items!.length == 0 ? <div className={'md:hidden flex flex-col w-full items-center'}>
                                <img src={emptyr} alt=""/>
                                <span className={'mt-6'}>سبد خرید خالی است</span>
                            </div> :
                            <div id="summary" className={`md:w-1/4 md:hidden block w-full md:px-8 px-0 my-[20px]`}>
                                <div className='font-semibold'>
                                    <div className={`form-control w-full mb-4`}>
                                        <div className="join rounded-none">
                                            <input
                                                value={codeCheck}
                                                onChange={(value) => setCodeCheck(value.target.value)}
                                                placeholder="کد تخفیف"
                                                className="focus:outline-none py-2  border rounded-lg border-[#e3e3e3] px-3 w-full placeholder:text-[12px] placeholder:text-[#121212] placeholder:font-semibold"/>
                                            {priceFromServer ? <button onClick={() => removeDiscountCode()}
                                                                       className="md:join-item text-[14px] text-[#DE0046] md:mr-2  px-3 py-2 bg-[#DE004633] rounded-lg mr-1">حذف
                                            </button> : <button onClick={() => checkDiscountCartValues()}
                                                                className="md:mr-2 text-[14px] text-[#4192EF] px-3 py-2 bg-[#4192EF33] rounded-lg mr-1 md:join-item">اعمال
                                            </button>}
                                        </div>
                                    </div>
                                    <div className="flex font-semibold justify-between text-sm uppercase">

                                        <span className={'text-[#737373] text-[12px]'}>جمع سبد خرید</span>
                                        <span
                                            className={'text-[#737373] text-[12px]'}>{moneyFormat(priceFromServer ?? totalPriceCount / 2, true)}</span>
                                    </div>
                                    <div className="mt-1.5 flex font-semibold justify-between text-sm uppercase">
                                        <span className={'text-[#737373] text-[12px]'}>هزینه ارسال</span>
                                        <span className={'text-[#737373] text-[12px]'}>{settings.map((e) => <div
                                            className={'flex flex-col'}>{e.name == "shipping_cost" ?
                                            <div>{e.value}ریال</div> :
                                            <div></div>}</div>)}</span>
                                    </div>

                                </div>
                                <div className='mt-5'></div>
                            </div>
                        }
                    </div>
                }
            </div>
            {
                loading ? <div>
                    {
                            <div className={'w-full flex flex-col justify-center items-center'}>
                                <img src={logo} alt="" className={'w-[30%]'}/>
                                <span className={'text-lg'}>سبد خرید خالی میباشد</span>
                            </div>
                    }
                </div> : cart?.items!.length === 0 || cart?.items === undefined || cart?.items === null ? '' :
                    <div className={'md:hidden block sticky bottom-0 bg-white'}>
                        <footer

                        >
                            <div className="form-control w-full mt-2">
                                <div className="join">
                                    <input
                                        value={description}
                                        onChange={(e) => setDescription(e.target.value)}
                                        placeholder="نشانی"
                                        className="focus:outline-none py-2 border rounded-lg border-[#e3e3e3] px-3 w-full placeholder:text-[12px] placeholder:text-[#121212] placeholder:font-semibold"
                                    />
                                </div>
                            </div>
                            <div className="form-control w-full mt-2 mb-2">
                                <div className="join">
                                    <input
                                        value={address}
                                        onChange={(e) => setAddress(e.target.value)}
                                        placeholder="توضیحات"
                                        className="focus:outline-none py-2 border rounded-lg border-[#e3e3e3] px-3 w-full placeholder:text-[12px] placeholder:text-[#121212] placeholder:font-semibold"
                                    />
                                </div>
                            </div>
                            {totalPriceCount !== 0 ? (
                                user?.id === 0 || !user ? (
                                    <div onClick={() => window.history.back()}>
                                        <label
                                            htmlFor="loginModal"
                                            className="mt-2 btn hover:text-[#4192EF] bg-[#4192EF] text-white w-full"
                                        >
                                            ابتدا وارد شوید
                                        </label>
                                    </div>
                                ) : (
                                    <button
                                        className="bg-[#4192EF] w-full md:w-[90%] h-10 text-white font-bold py-2 px-4 rounded-xl"
                                        onClick={() => checkoutCartValues()}
                                    >
                                        پرداخت
                                    </button>
                                )
                            ) : null}
                        </footer>
                    </div>

            }
        </DefaultModal>
    );
};

export default CartModal;
