import React, {useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from 'redux/hooks';
import {defaultModal, getSettingByKey, NetworkImage} from 'utils/funcions';
import {RootState} from "../../../redux/store";
import {checkUser} from "../../../utils/funcions";
import Routes from "../../../utils/routing/routes";
import {Link, useLocation} from "react-router-dom";
import {parseNumber} from "react-advanced-cropper";
import {useSelector} from "react-redux";
import {Highlights} from "./highlights";
import Auth from "../../../services/savedData/auth";
import {signUpAsDefault} from "../../../redux/user/login/loginSlice";
import {EditProfileModal} from "../profile/edit";
import {IconMessageChatbot, IconUsers} from "@tabler/icons-react";

function Header() {
    const location = useLocation();
    const [showBottomSheet, setThatSide] = useState(false);
    const [showNextSide, setShowBottomSheet] = useState(false);
    // const {products} = useAppSelector((state: RootState) => state.adminProducts);
    const changeShowBottomSheet = () => {
        setShowBottomSheet(!showBottomSheet);
    }
    const changeSide = () => {
        setThatSide(!showNextSide);
    }
    const {mainUsersList} = useAppSelector((state: RootState) => state.mainUsers);
    const userType = checkUser();

    const {category, orders, products, user, shop, loading, usersCount} = useAppSelector((state: RootState) => state.user);
    const {settingFields, settings, settingFamilies} = useSelector((state: RootState) => state.user);

    const phoneNumber = settings.find(e => e.name === 'phone_number')?.value;

    const [isPressed, setIsPressed] = useState(false);

    const highLights = useAppSelector((state: RootState) => state.highlights);
    const [coords, setCoords] = useState({ x: 0, y: 0 });
    const [isActive, setIsActive] = useState(false);

    const handleClick = (e: { target: { getBoundingClientRect: () => any; }; clientX: number; clientY: number; }) => {
        const rect = e.target.getBoundingClientRect();
        setCoords({
            x: e.clientX - rect.left,
            y: e.clientY - rect.top
        });
        setIsActive(true);
        setTimeout(() => setIsActive(false), 600); // مدت زمان انیمیشن
    };
    const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);

    return (
        loading ? '' :
            <div className='flex flex-col w-full md:mt-[30px] mt-[55px] pr-2 pl-1'>
                <EditProfileModal handleIsOpen={(e: boolean) => setIsProfileModalOpen(e)} isOpen={isProfileModalOpen}/>

                <div className='flex flex-col w-full mt-2'>
                    <div className='flex md:justify-start items-center md:items-start'>
                        <Link to={Routes.aboutUs.path} className="rounded-full">
                            <div
                                className={`rounded-full relative lg:w-[128px] md:w-[112px] md:h-[112px] lg:h-[128px] ${highLights.highLights != null && highLights.highLights!.length != 0 ? "bg-gradient-to-b from-[#DE0046] to-[#F7A34B]" : "bg-white"} flex w-[76px] h-[76px] justify-center items-center `}>

                                <div
                                    className='rounded-full relative bg-white flex lg:w-[123px] md:w-[107px] md:h-[107px] lg:h-[123px] w-[71px] h-[71px] justify-center items-center'>

                                    <NetworkImage
                                        data-te-img="https://mdbcdn.b-cdn.net/img/Photos/Slides/2.webp"
                                        alt=""
                                        className="object-cover lg:w-[122px] md:w-[106px] md:h-[106px] lg:h-[122px] flex justify-center items-center w-[70px] h-[70px] data-[te-lightbox-disabled]:cursor-auto rounded-full"
                                        url={
                                            shop!.avatar
                                        }/>
                                </div>
                            </div>
                        </Link>

                        <div className='flex-col mr-5'>
                            <div className="w-full hidden justify-start items-start mt-2 md:flex-col md:flex">
                            <span className='text-black font-medium text-[22px]'>
                                <span>{settings.find(e => e.name === 'shop_name')?.value}</span>
                                {/*<span>{shop!.description}</span>*/}

                            </span>
                                <span
                                    className={'md:max-w-[400px] lg:max-w-[500px] text-[14px] text-[#777777]'}>{shop!.description != null ? shop!.description.length > 500 ? shop!.description.substring(0, 500) + '...' : shop!.description : "بیوگرافی ندارد."}</span>

                            </div>
                            <div className='w-full flex mt-0 md:mt-4 justify-between md:justify-start '>
                                <div className="md:flex-row flex flex-col items-center">
                                    <span
                                        className='text-black font-normal text-[16px] md:text-base'>{parseNumber(products!)}</span>
                                    <span
                                        className='text-black font-medium md:mr-2 md:text-[16px] text-[11px]'>پست</span>
                                </div>
                                {
                                    user!.id === shop!.id ?
                                        <Link to={Routes.shopUsers.path}
                                              className="md:flex-row flex flex-col items-center mr-6 lg:mr-12 md:mr-8">
                                        <span
                                            className='text-black font-normal text-[16px] md:text-base'>{usersCount}</span>
                                            <span
                                                className='text-black font-medium md:mr-2 md:text-[16px] text-[11px]'>کاربران</span>
                                        </Link> :
                                        <div className="md:flex-row flex flex-col items-center mr-6 lg:mr-12 md:mr-8">
                                        <span
                                            className='text-black font-normal text-[16px] md:text-base'>{usersCount}</span>
                                            <span
                                                className='text-black font-medium md:mr-2 md:text-[16px] text-[11px]'>کاربران</span>
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-col mt-1 md:hidden'>
                        <p className='leading-5 flex flex-col'>
                            <p className='text-xs mt-2 flex items-center text-gray-500'>
                                <span
                                    className='text-base ml-[3px] mr-2 text-black font-medium'>{settings.find(e => e.name === 'shop_name')?.value}</span>
                            </p>
                            <p className='text-xs mt-1 flex items-center text-gray-500'>
                            <span
                                className={'max-w-[400px] text-[13px] mr-2 text-[#121212]'}>{shop!.description != null ? shop!.description.length > 80 ? shop!.description.substring(0, 80) + '...' : shop!.description : "بیوگرافی ندارد."}</span>
                            </p>
                            {/*<p className='text-xs mt-1 flex items-center text-gray-500'>*/}
                            {/*    <a href={`tel:${phoneNumber}`}*/}
                            {/*       className='ml-[3px] mr-2 text-[13px] text-black font-medium'>شماره تماس*/}
                            {/*        : {settings.find(e => e.name === 'phone_number')?.value}</a>*/}
                            {/*</p>*/}
                            <a href={`mailto:${settings.find(e => e.name === 'email')?.value}`}
                               className='text-xs mt-1 flex items-center text-[#154E8C]'>
      <span
          className='text-[14px] ml-[3px] mr-2 text-[#154E8C] font-normal'>{settings.find(e => e.name === 'email')?.value}</span>
                            </a>
                        </p>
                    </div>
                    <div className={'flex flex-col w-full mt-2 max-w-7xl'}>

                        <div className='flex justify-between w-full mt-2 max-w-7xl'>
                            {(
                                <Link to={user!.id != shop!.id ? Routes.chat(shop!.id).path : Routes.users.path}
                                      className={`flex flex-1 justify-center items-center hover:border-white bg-white rounded-lg cursor-pointer border border-[#9999999] text-[14px]`}>
                                    <div
                                        // to={user?.id !== shop?.id ? Routes.chat(shop?.id).path : Routes.users.path}
                                        className={`group button-ripple flex flex-1 py-2 duration-300 justify-center items-center bg-white rounded-lg cursor-pointer border border-[#9999999] text-[14px] ${isActive ? 'active' : ''}`}
                                        //@ts-ignore
                                        onClick={handleClick}
                                        //@ts-ignore
                                        style={{'--ripple-x': `${coords.x}px`, '--ripple-y': `${coords.y}px`}}
                                    >
            <span className='text-black flex text-[14px]'>
                <IconMessageChatbot className={'group-hover:w-10 w-0 duration-300'}/>
                {userType === 'shop' ? "لیست پیام ها " : "ارسال پیام"}
            </span>
                                    </div>
                                </Link>

                            )}
                            <div className='w-2'>

                            </div>
                            {
                                user!.id === 0 || !user ? <label htmlFor={'loginModal'}
                                                                 className='flex cursor-pointer flex-1 py-2 group justify-center items-center hover:bg-[#4192EF33] hover:text-[#4192EF] text-white hover:border-[#4192EF33] duration-300 bg-[#4192EF] rounded-lg border border-[#4192EF] text-[14px]'>

                                    <IconUsers className={'group-hover:w-10 w-0 duration-300'}/>
                                    <span className='text-[14px]'>اعضای هیئت</span>
                                </label> : user!.id !== shop!.id ? <label

                                    onClick={() => {
                                        // if (!Auth.get()) {
                                        //     return dispatch(signUpAsDefault());
                                        // }
                                        // if (user!.id != 0) {
                                        setIsProfileModalOpen(true)
                                        // }
                                    }}
                                    htmlFor={'profileModal'}
                                    className='flex cursor-pointer flex-1 py-2 group justify-center items-center hover:bg-[#4192EF33] hover:text-[#4192EF] text-white hover:border-[#4192EF33] duration-300 bg-[#4192EF] rounded-lg border border-[#4192EF] text-[14px]'>

                                    <IconUsers className={'group-hover:w-10 w-0 duration-300'}/>
                                    <span className='text-[14px]'>اعضای هیئت</span>
                                </label> : <Link to={Routes.shopUsers.path}
                                                 className='flex flex-1 py-2 group justify-center items-center hover:bg-[#4192EF33] hover:text-[#4192EF] text-white hover:border-[#4192EF33] duration-300 bg-[#4192EF] rounded-lg border border-[#4192EF] text-[14px]'>

                                    <IconUsers className={'group-hover:w-10 w-0 duration-300'}/>
                                    <span className='text-[14px]'>اعضای هیئت</span>
                                </Link>
                            }
                        </div>
                        <div className={'flex w-full min-h-24'}>
                            <Highlights/>
                        </div>

                    </div>

                </div>
            </div>
    );
}


export default Header;
// export default Header;
