import {IPath} from "../types";
import * as React from "react";
import VerifyCodeGuard from "../../middlewares/verifyCode";
import AuthGuard from "../../middlewares/auth";
import GuestGuard from "../../middlewares/guest";
import {getAll as getAllCategories} from "../../redux/dashboard/categories/categoriesAction";
import {getProductsCategory} from "../../redux/dashboard/categories/productsCategoryAction";
import {getAll as getAllProducts, getProduct} from "../../redux/dashboard/products/productsAction";
import {getAllHighLights} from "../../redux/dashboard/stories/storiesAction";
import {getProductById} from "../../redux/dashboard/productPage/productPageAction";
import {getAll as getAllQuestions} from "../../redux/dashboard/questions/questionsAction";
import {getAll as getAllDiscountCodes} from "redux/dashboard/discountCodes/discountCodesAction";
import {getAll as getAllContacts} from "../../redux/dashboard/contacts/contactsAction";
import {getAll as getAllCustomers} from "../../redux/dashboard/customers/customersAction";
import {getAll as getAllFormQuestions} from "../../redux/dashboard/formQuestions/formQuestionsAction";
import {getAll as getCart} from "../../redux/dashboard/cart/cartAction";
import {getAll as getAllContactsAndCustomers} from "../../redux/dashboard/contactsAndCustomers/contactsAndCustomersAction";
import {getAll as getAllAdvancedCategory} from "../../redux/dashboard/advancedCategory/advancedCategoryAction";
import {getHighlights} from "../../redux/dashboard/highLights/highLightsAction"
import Categories from "pages/front/categories";
import Products from "pages/front/products";
import DiscountCodes from "pages/front/discountCodes";
import createRoutes from "./baseRoutes";
import CartModal from "../../pages/front/cart";
import Customer from "../../pages/front/customer";
import ContactsAndCustomers from "../../pages/front/contactsAndCustomers";
import ProductPageModal from "../../pages/front/productPage";
import EditMainSettings from "../../pages/front/settings/mainSettings";
import {AboutUs} from "../../pages/front/aboutUs";
import {ContactUs} from "../../pages/front/contactUs";
import {ResellerInfo} from "../../pages/front/reseller/resellerInfo";
import {ReferralInfo} from "../../pages/front/reseller/referralInfo";
import FormQuestions from "../../pages/front/formQuestions";
import Questions from "../../pages/front/questions";
import {getFormQuestionById} from "../../redux/dashboard/answers/answersAction";
import {AnswerForm} from "../../pages/front/answerForm";
import {SuccessAnswerSubmitted} from "../../pages/front/successAnswerSubmitted";
import Contacts from "../../pages/front/contacts";
import AdvancedCategory from "../../pages/front/advancedCategory";
import LTV from "../../pages/front/lTV";
import {getAll} from "../../redux/dashboard/lTV/lTVAction";
import PeopleStoryModal from "../../pages/front/story";
import {getOrderCart} from "../../redux/dashboard/orders/orderAction";
import CategoryProductList from "../../pages/front/categories/categoriesList";
import {getAllCountResellers, getAllResellers} from "../../redux/dashboard/reseller/resellerAction";
import {Order} from "../../pages/front/orders/show";
import {getOrderById} from "../../redux/dashboard/orderPage/orderPageAction";
import ResellerPaymentsInfo from "../../pages/front/reseller/index";
import {getAllReferralCheckouts, getAllResellersChart} from "../../redux/dashboard/referrall/referrallAction";
import Plans from "../../pages/front/plans";
import {getAllPlans} from "../../redux/dashboard/plans/plansAction";
import {getAllBuyed} from "../../redux/dashboard/plans/buyedPlansAction";
import {getChart} from "../../redux/dashboard/resellerChart/resellerChartAction";
import StoryPageModal from "../../pages/front/story";
import Orders from "../../pages/front/orders";
import ShopGuard from "../../middlewares/shop";
import NoPlanGuard from "../../middlewares/noPlan";
import {getAllRFM} from "../../redux/dashboard/RFM/RFMAction";
import ChartRFM from "../../pages/front/chart/RFM";
import ChartTBP from "../../pages/front/chart/TBP";
import {getAllTBP} from "../../redux/dashboard/TBP/TBPAction";
import Login from "../../pages/front/auth/login";
import LoginRedirect from "pages/front/auth/loginRedirect";
import Chat from "pages/front/messenger";
import {getAllChats} from "../../redux/dashboard/messenger/chatAction";
import ChatForm from "pages/front/messenger";
import Users from "../../pages/front/messenger/users";
import {getAllUsersChat} from "../../redux/dashboard/messenger/usersChatAction";
import {getAllUsersForSingleMainPage} from "../../redux/dashboard/users/UsersAction";
import ShopPlans from "pages/front/shopPlans";
import {SettlementOfAccount} from "../../pages/front/settlementOfAccounts/show";
import SettlementOfAccounts from "pages/front/settlementOfAccounts";
import {getSettlementOfAccountCart} from "../../redux/dashboard/settlementOfAccounts/settlementOfAccountAction";
import UsersIndex from "../../pages/front/users";
import Answers from "pages/front/answers";
import {getQuestionAnswers} from "../../redux/dashboard/questionAnswers/questionAnswersAction";
import FormQuestionAnswers from "../../pages/front/formQuestionAnswers";
import {
    getFormQuestionUsers
} from "../../redux/dashboard/formQuestionUsers/formQuestionUsersAction";
import {getFormQuestionUserAnswer} from "../../redux/dashboard/userAnswersOnQuestions/userAnswersOnQuestionAction";
import {UserAnswerInfo} from "../../pages/front/formQuestionAnswers/show";
import {getAllShops} from "../../redux/dashboard/shopUsers/ShopUsersAction";
import ShopPlansList from "../../pages/front/shopPlans/list";
import {getAllShopPlans} from "../../redux/dashboard/shopPlans/shopPlansAction";
import {getAll as analyticsGetAll} from "redux/dashboard/analytics/analyticsAction";
import {AnalyticsMainPage} from "../../pages/front/analytics/AnalyticsMainPage";
import AnalyticsDashboard from "../../pages/front/analytics/AnalyticsDashboard";
import {AdvancedAnalyticsPage} from "../../pages/front/analytics/advancedAnalyticsPage";
import {CategoriesAnalyticsPage} from "../../pages/front/analytics/categoriesAnalyticsPage";
import {ProductsAnalyticsPage} from "../../pages/front/analytics/productsAnalyticsPage";
import {UsersAnalyticsPage} from "../../pages/front/analytics/usersAnalyticsPage";
import {getAllUTMs} from "../../redux/dashboard/utm/utmAction";
import UTMsList from "../../pages/front/utms/list";
import {getUserInfo} from "../../redux/user/user/userAction";
import BuyedPlansList from "../../pages/front/plans/buyedPlanList";
import {getCategoriesForAddProduct} from "../../redux/dashboard/catForAddProd/catForAddProdAction";
import {ContactUs2} from "../../pages/front/contactUs2";
import {EightUserAction} from "../../redux/dashboard/users/EightUserAction";
import {blockUserFromShop} from "../../redux/dashboard/users/UsersAction";
import FormQuestionAnswersList from "../../pages/front/formQuestionAnswers/list";
import * as url from "node:url";
import {getAllDiscountProducts} from "../../redux/dashboard/discountCodes/discountProductsAction";
// import {useSelector} from "../../redux/hooks";
import {RootState} from "../../redux/store";
import ReferralCheckouts from "../../pages/front/reseller/referralCheckouts";
import {WarehousingMainPage} from "../../pages/front/warehousing/main";
import WarehouseInventoryProducts from "../../pages/front/warehousing/warehouseProducts";
import WarehouseInventoryProductsInventory from "../../pages/front/warehousing/warehouseProductsInventory";
import {getAllInventoryProducts} from "../../redux/dashboard/products/inventoryProductsAction";
import WarehouseOrdersDetail from "pages/front/warehousing/warehousOrdersDetail";
import WarehouseProductsDetails from "pages/front/warehousing/productsDetail";
import {getAllNotifications} from "../../redux/dashboard/notifications/unreadNotificationsAction";
import NotificationsList from "../../pages/front/notifications/notifications";
import {AsyncThunk} from "@reduxjs/toolkit";
import DocsList from "../../pages/front/docs";
import {getAllDocs} from "../../redux/dashboard/docs/docAction";

// const {shop, settings} = useSelector((state: RootState) => state.user);
export default class Routes {
    static products: IPath = {
        path: '/products',
        name: 'products',
        middleware: [NoPlanGuard],
        preLoadingMethod: [
            {action: getHighlights},
            {action: getAllProducts},
            {action: getAllCategories},
            {action: EightUserAction},
            {action: getCategoriesForAddProduct},
            {action: getUserInfo},
            {action: getAllPlans},
            {action: getAllUsersForSingleMainPage},

        ]
    }

    //admin routes
    static dashboard: IPath = {
        path: '/dashboard',
        name: 'dashboard',
        middleware: [NoPlanGuard,AuthGuard],
        preLoadingMethod: [
            {action: getHighlights},]
    };
    //admin routes
    static docs: IPath = {
        path: '/docs',
        name: 'docs',
        middleware: [NoPlanGuard,AuthGuard],
        preLoadingMethod: [
            {action: getAllDocs},
        ]
    };
    //admin EditMainSettings
    static editMainSettings: IPath = {
        path: '/editMainSettings',
        name: 'editMainSettings',
        middleware: [NoPlanGuard,ShopGuard],
        preLoadingMethod: [

            {action: getHighlights},
        ]
    };

    //
    // static chat: IPath = {
    //     path: '/chat',
    //     name: 'chat',
    //     preLoadingMethod: [
    //         {action: getAllChats},
    //         // {action: getAllProducts}
    //     ]
    //     // middleware: [NoPlanGuard,ShopGuard]
    // };
    //admin EditMainSettings
    static aboutUs: IPath = {
        path: '/aboutUs',
        name: 'aboutUs',
        middleware: [NoPlanGuard],
        preLoadingMethod: [

            {action: getHighlights},
            {action: getUserInfo},
        ]
    };
    //admin EditMainSettings
    static contactUs: IPath = {
        path: '/contactUs',
        name: 'contactUs',
        preLoadingMethod: [

            {action: getHighlights},
        ],
        middleware: [NoPlanGuard,]
    };
    static charts: IPath = {
        path: '/charts',
        name: 'charts',
        preLoadingMethod: [
            {action: getHighlights},
            {action: getAllRFM}
        ]
    };
    static TBP: IPath = {
        path: '/TBP',
        name: 'TBP',
        middleware: [NoPlanGuard,ShopGuard],
        preLoadingMethod: [
            {action: getHighlights},
            {action: getAllTBP}
        ]
    };
    //admin EditMainSettings
    static resellerInfo: IPath = {
        path: '/resellerInfo',
        middleware: [NoPlanGuard,AuthGuard],
        name: 'resellerInfo',
        preLoadingMethod: [

            {action: getHighlights},
        ]
    };
    //admin EditMainSettings
    static resellerInfoUser: IPath = {
        path: '/userResellerInfo',
        middleware: [NoPlanGuard,AuthGuard],
        name: 'userResellerInfo',
    };
    //admin EditMainSettings
    static resellerPaymentsInfo: IPath = {
        preLoadingMethod: [
            {action: getHighlights},
            {action: getAllResellers},
            {action: getChart},
            // {action: },
        ],
        path: '/resellerPaymentsInfo',
        name: 'resellerPaymentsInfo'
    };
    //admin EditMainSettings
    static referralInfo: IPath = {
        path: '/referralInfo',
        name: 'referralInfo',
        middleware: [NoPlanGuard,AuthGuard],
        preLoadingMethod: [
            {action: getHighlights},
            {action: getAllResellersChart}
        ]
    };
    //admin EditMainSettings
    static referralInfoUser: IPath = {
        path: '/userReferralInfo',
        name: 'userReferralInfo',
        middleware: [NoPlanGuard,AuthGuard],
        preLoadingMethod: [
            {action: getHighlights},
            {action: getAllResellersChart}
        ]
    };
    //admin EditMainSettings
    static formQuestions: IPath = {
        path: '/formQuestions',
        name: 'formQuestions',
        middleware: [NoPlanGuard,ShopGuard],
        preLoadingMethod: [
            {action: getHighlights},
            {action: getAllFormQuestions},
        ]
    };
    static formQuestionQuestions = (id?: string): IPath => {
        return {
            path: `${this.formQuestions.path}/${id || ':id'}/formQuestionQuestions`,
            name: 'formQuestionQuestions',
            middleware: [NoPlanGuard,ShopGuard],
            preLoadingMethod: [
                {action: getHighlights},
                {action: getAllQuestions, values: ['id']},
            ]
        }
    };
    static answerToQuestionForm = (id?: string): IPath => {
        return {
            path: `form/${id || ':id'}/answer`,
            name: 'answers',
            // middleware: [NoPlanGuard,ShopGuard],
            preLoadingMethod: [
                {action: getHighlights},
                {action: getFormQuestionById, values: ['id']},
            ]
        }
    };
    static FormQuestionAnswers = (id?: string): IPath => {
        return {
            path: `formAnswers/${id || ':id'}/answer`,
            name: 'answers',
            middleware: [NoPlanGuard,ShopGuard],
            preLoadingMethod: [
                {action: getHighlights},
                {action: getFormQuestionById, values: ['id']},
            ]
        }
    };
    static questionAnswers = (id?: number): IPath => {
        return {
            path: `/answers/${id || ':id'}/`,
            name: 'answers',
            middleware: [NoPlanGuard,ShopGuard],
            preLoadingMethod: [
                {action: getHighlights},
                {action: getQuestionAnswers, values: ['id']},
            ]
        }
    };
    static formQuestionUser = (id?: string): IPath => {
        return {
            path: `/formQuestionUser/${id || ':id'}/`,
            name: 'answers',
            middleware: [NoPlanGuard,ShopGuard],
            preLoadingMethod: [
                {action: getHighlights},
                {action: getFormQuestionUsers, values: ['id']},
            ]
        }
    };
    static product = (id?: number): IPath => {
        return {
            path: `${this.products.path}/${id || ':id'}`,
            name: 'product',
            preLoadingMethod: [
                {action: getHighlights},
                {action: getProduct, values: ['id']},
                {action: getAllCategories},
                {action: getHighlights},
                {action: getAllProducts},
            ]
        }
    };
    static login: IPath = {
        path: '/login',
        name: 'login',
        middleware: [NoPlanGuard,GuestGuard]
    };
    static shopUsers: IPath = {
        path: '/shopUsers',
        name: 'shopUsers',
        preLoadingMethod: [
            {action: getHighlights},
            {action: getAllUsersForSingleMainPage},
        ],
        middleware: [NoPlanGuard,ShopGuard]
    };
    static loginRedirect: IPath = {
        path: '/loginRedirect',
        name: 'loginRedirect',
    };
    static register: IPath = {
        path: '/register',
        name: 'register',
        middleware: [NoPlanGuard,GuestGuard]
    };
    static verifyCode: IPath = {
        path: '/verifyCode',
        name: 'verifyCode',
        middleware: [NoPlanGuard,GuestGuard, VerifyCodeGuard]
    }
static settlementOfAccount = (id?: number): IPath => {
        return {
            path: `${this.settlementOfAccounts.path}/${id || ':id'}`,
            name: 'settlementOfAccountPage',
            middleware: [NoPlanGuard,ShopGuard],
            preLoadingMethod: [
                {action: getHighlights},
                {action: getSettlementOfAccountCart, values: ['id']},
            ]
        }
    };
    static settlementOfAccounts: IPath = {
        path: '/settlementOfAccounts',
        name: 'SettlementOfAccounts',
        middleware: [NoPlanGuard,ShopGuard],
        preLoadingMethod: [
            {action: getHighlights},
            {action: getSettlementOfAccountCart},
        ]
    };
    static plans: IPath = {
        path: '/plans',
        name: 'plans',
        middleware: [NoPlanGuard,],
        preLoadingMethod: [
            {action: getHighlights},
            {action: getAllPlans},
        ]
    };
    static buyedPlans : IPath = {
        path: '/purchased',
        name: 'purchased',
        middleware: [NoPlanGuard,],
        preLoadingMethod: [
            {action: getHighlights},
            {action: getAllBuyed},
        ]
    };
    static shopPlans: IPath = {
        path: '/shopPlans',
        name: 'shopPlans',
        middleware: [NoPlanGuard,],
        preLoadingMethod: [
            {action: getHighlights},
            {action: getAllShopPlans},
        ]
    };
    static utms: IPath = {
        path: '/utm',
        name: 'utm',
        middleware: [NoPlanGuard],
        preLoadingMethod: [
            {action: getHighlights},
            {action: getAllUTMs},
        ]
    };
    static withoutShopPlans: IPath = {
        path: '/withoutShopPlans',
        name: 'withoutShopPlans',
        middleware: [],
        preLoadingMethod: [
            {action: getHighlights},
            {action: getAllPlans},
        ]
    };
    static categoriesList: IPath = {
        path: '/categoriesList',
        name: 'categoriesList',
        middleware: [NoPlanGuard,AuthGuard],
        preLoadingMethod: [
            {action: getHighlights},
            {action: getAllCategories},
        ]
    };

    static chat = (id?: number): IPath => {
        return {
            preLoadingMethod: [
                {action: getHighlights},
                {action: getAllChats, values: ['id']},
                {action: getAllUsersChat},

                // {action: getAllUsersChat},
            ],
            path: `/msgs/${id || ':id'}`,
            name: 'msgs',
            middleware:[],

        }
    };
    static stories = (id?: number): IPath => {
        return {
            preLoadingMethod: [
                {action: getAllHighLights, values: ['id']},
            ],
            // path: '/stories',
            path: `/stories/${id || ':id'}`,
            name: 'stories',
            middleware: [NoPlanGuard,]
        }
    };
    static categoriesProductsList = (id?: number): IPath => {
        return {
            path: `${this.categoriesList.path}/${id || ':id'}`,
            name: 'productsCategory',
            middleware: [NoPlanGuard,],
            preLoadingMethod: [
                {action: getHighlights},
                {action: getProductsCategory, values: ['id']},
                // {action: getAllProducts}
            ]
        }
    };

    static discountCodes: IPath = {
        path: '/discountCodes',
        name: 'discountCodes',
        middleware: [NoPlanGuard,ShopGuard],
        preLoadingMethod: [
            {action: getHighlights},
            {action: getAllDiscountCodes},
            {action: getAllCategories},
            {action: getAllDiscountProducts},
            {action: getAllProducts},
        ]
    };

    static users: IPath = {
        path: '/users',
        name: 'users',
        preLoadingMethod: [
            {action: getHighlights},
            {action: getAllUsersChat},
            {action: getAllChats},
        ]
    };
    static categories: IPath = {
        path: '/categories',
        name: 'categories',
        middleware: [NoPlanGuard,],
        preLoadingMethod: [
            {action: getHighlights},
            {action: getAllCategories}
        ]
    }
    static contactsAndCustomersRoute: IPath = {
        path: '/getContactsCustomers',
        name: 'contactsAndCustomers',
        middleware: [NoPlanGuard,ShopGuard],
        preLoadingMethod: [
            {action: getHighlights},
            {action: getAllContactsAndCustomers}
        ]
    }
    static advancedCategoryRoute: IPath = {
        path: '/customer/categorize',
        name: 'customerCategorize',
        middleware: [NoPlanGuard,ShopGuard],
        preLoadingMethod: [
            {action: getHighlights},
            {action: getAllAdvancedCategory}
        ]
    }
    static LTVRoute: IPath = {
        path: '/customer/LTV',
        name: 'LTV',
        middleware: [NoPlanGuard,ShopGuard],
        preLoadingMethod: [
            {action: getHighlights},
            {action: getAll}
        ]
    }

    static contacts: IPath = {
        path: '/contacts',
        name: 'contacts',
        middleware: [NoPlanGuard,AuthGuard],
        preLoadingMethod: [
            {action: getHighlights},
            {action: getAllContacts}
        ]
    }

    static customers: IPath = {
        path: '/customers',
        name: 'customers',
        middleware: [NoPlanGuard,ShopGuard],
        preLoadingMethod: [
            {action: getHighlights},
            {action: getAllCustomers}
        ]
    }

    static referralCheckouts: IPath = {
        path: '/referralUsers',
        name: 'referralUsers',
        middleware: [NoPlanGuard,ShopGuard],
        preLoadingMethod: [
            {action: getHighlights},
            {action: getAllReferralCheckouts}
        ]
    }

    static order = (id?: number): IPath => {
        return {
            path: `${this.orders.path}/${id || ':id'}`,
            name: 'orderPage',
            middleware: [NoPlanGuard,AuthGuard],
            preLoadingMethod: [
                {action: getHighlights},
                {action: getOrderById, values: ['id']},
            ]
        }
    };

    static userAnswersWithQuestions = (id?: string, urls?: string): IPath => {
        console.log('datadata', urls, id)
        // let url = urls
        return {
            path: `/questionUserAnswers/${id || ':id'}/${urls || ':urls'}`,
            name: 'answers',
            middleware: [NoPlanGuard, ShopGuard],
            preLoadingMethod: [
                {action: getHighlights},
                //@ts-ignore
                {action: getFormQuestionUserAnswer, values: ['id', 'urls']},
            ]
        }
    };

    static cart: IPath = {
        path: '/cart',
        name: 'cart',
        // preLoadingMethod: [
        //     {action: getOrderCart},
        //     {action: getCart},
        // ]
    }

    static orders: IPath = {
        path: '/orders',
        name: 'orders',
        preLoadingMethod: [
            {action: getHighlights},
            {action: getOrderCart}
        ]
    }
    static shoporders: IPath = {
        path: '/allOrders',
        name: 'orders',
        preLoadingMethod: [
            {action: getHighlights},
            {action: getOrderCart}
        ]
    }

    static home: IPath = {
        path: '/',
        name: 'home',
        middleware: [NoPlanGuard,],
        preLoadingMethod: [
            {action: getHighlights},
            {action: getAllProducts},
            {action: getAllCategories},
            {action: getCategoriesForAddProduct},
            {action: getUserInfo},
            {action: getAllPlans},
            {action: EightUserAction},
            {action: getAllUsersForSingleMainPage},
            {action: getAllShops}
        ],
        usingCachePreloadingMethod: [
            {action: getAllProducts},
        ],
    };

    static successAnswerSubmitted: IPath = {
        path: '/successAnswerSubmitted',
        name: 'successAnswerSubmitted',
        middleware: [NoPlanGuard,ShopGuard]
    };

    static analyticsDashboard: IPath = {
        path: '/analytics',
        name: 'analytics',
        middleware: [NoPlanGuard,ShopGuard],
        preLoadingMethod: [
            {action: getHighlights},
            {action: analyticsGetAll}
        ]
    }

    static warehousingDashboard: IPath = {
        path: '/warehouse',
        name: 'warehouse',
        middleware: [NoPlanGuard,ShopGuard],
        preLoadingMethod: [
            {action: getAllProducts},
        ]
    }
    // static notifications: IPath = {
    //     path: '/notifications',
    //     name: 'notifications',
    //     middleware: [NoPlanGuard,ShopGuard],
    //     preLoadingMethod: [
    //         // {action: getAllNotifications},
    //     ]
    // }
    static warehousingInventoryProducts: IPath = {
        path: '/warehouse/inventory_products',
        name: 'warehouseInventoryProducts',
        middleware: [NoPlanGuard,ShopGuard],
        preLoadingMethod: [

            {action: getHighlights},
            {action: getAllProducts},
            {action: getAllCategories},
            {action: getCategoriesForAddProduct},
            // {action: getHighlights},
            // {action: analyticsGetAll}
        ]
    }
    static warehousingAllProductsInventory: IPath = {
        path: '/warehouse/warehouse_products_inventory',
        name: 'warehouseWarehouseProductsInventory',
        middleware: [NoPlanGuard,ShopGuard],
        preLoadingMethod: [
            {action: getAllProducts},
            {action: getAllInventoryProducts},
        ]
    }
    static warehousingProductsDetails: IPath = {
        path: '/warehouse/warehouse_products_details',
        name: 'warehouseWarehouseProductsDetails',
        middleware: [NoPlanGuard,ShopGuard],
        preLoadingMethod: [
            {action: getAllProducts},
            {action: getAllInventoryProducts},
        ]
    }
    static warehousingOrdersDetail: IPath = {
        path: '/warehouse/orders',
        name: 'warehouseOrdersDetail',
        middleware: [NoPlanGuard,ShopGuard],
        preLoadingMethod: [
            {action: getAllProducts},
            {action: getAllInventoryProducts},
            {action: getOrderCart}
        ]
    }
    static advancedAnalytics: IPath  = {
        path: '/analytics/advancedAnalytics',
        name: 'advancedAnalytics',
        middleware: [NoPlanGuard, AuthGuard],
        preLoadingMethod: [
            {action: getHighlights},
            {action: analyticsGetAll}
        ]
    }
    static categoriesAnalytics: IPath  = {
        path: '/analytics/categoriesAnalytics',
        name: 'categoriesAnalytics',
        middleware: [NoPlanGuard, AuthGuard],
        preLoadingMethod: [
            {action: getHighlights},
            {action: analyticsGetAll}
        ]
    }
    static productsAnalytics: IPath  = {
        path: '/analytics/productsAnalytics',
        name: 'productsAnalytics',
        middleware: [NoPlanGuard, AuthGuard],
        preLoadingMethod: [
            {action: getHighlights},
            {action: analyticsGetAll}
        ]
    }
    static usersAnalytics: IPath  = {
        path: '/analytics/usersAnalytics',
        name: 'usersAnalytics',
        middleware: [NoPlanGuard, AuthGuard],
        preLoadingMethod: [
            {action: getHighlights},
            {action: analyticsGetAll}
        ]
    }
    static RFMAnalytics: IPath = {
        path: '/analytics/RFM',
        name: 'RFM',
        middleware: [NoPlanGuard, AuthGuard],
        preLoadingMethod: [
            {action: getHighlights},
            {action: getAllRFM}
        ]
    }

}


export const indexRoutes = (): React.ReactElement[] => createRoutes([
    {
        path: Routes.home,
        component: <Products/>,
    },
    {
        path: Routes.products,
        component: <Products/>,
    },
    {
        path: Routes.loginRedirect,
        component: <LoginRedirect/>,
    },
    {
        path: Routes.shopUsers,
        component: <UsersIndex/>,
    },
    {
        path: Routes.plans,
        component: <Plans/>,
    },
    {
        path: Routes.buyedPlans,
        component: <BuyedPlansList/>,
    },
    {
        path: Routes.settlementOfAccount(),
        component: <SettlementOfAccount/>,
    },
    {
        path: Routes.settlementOfAccounts,
        component: <SettlementOfAccounts/>,
    },
    {
        path: Routes.shopPlans,
        component: <ShopPlans/>,
    },
    {
        path: Routes.utms,
        component: <UTMsList/>,
    },
    {
        path: Routes.discountCodes,
        component: <DiscountCodes/>,
        children: []
    },
    {
        path: Routes.aboutUs,
        component: <AboutUs/>,
        children: []
    },
    {
        path: Routes.formQuestions,
        component: <FormQuestions/>,
        children: []
    },
    {
        path: Routes.formQuestionQuestions(),
        component: <Questions/>,
        children: []
    },
    {
        path: Routes.answerToQuestionForm(),
        component: <AnswerForm/>,
        children: []
    },
    {
        path: Routes.questionAnswers(),
        component: <Answers/>,
        children: []
    },
    {
        path: Routes.formQuestionUser(),
        component: <FormQuestionAnswersList/>,
        children: []
    },
    {
        path: Routes.product(),
        component: <ProductPageModal/>,
        children: []
    },
    {
        path: Routes.successAnswerSubmitted,
        component: <SuccessAnswerSubmitted/>,
        children: []
    },
    {
        path: Routes.contactUs,
        component: <ContactUs/>,
        children: []
    },
    {
        path: Routes.charts,
        // @ts-ignore
        component: <ChartRFM/>,
        children: []
    },
    {
        path: Routes.TBP,
        // @ts-ignore
        component: <ChartTBP/>,
        children: []
    },
    {
        path: Routes.referralInfo,
        component: <ReferralInfo/>,
        children: []
    },
    {
        path: Routes.referralInfoUser,
        component: <ReferralInfo/>,
        children: []
    },
    {
        path: Routes.resellerInfo,
        component: <ResellerInfo/>,
        children: []
    },
    {
        path: Routes.resellerInfoUser,
        component: <ResellerInfo/>,
        children: []
    },
    {
        path: Routes.resellerPaymentsInfo,
        component: <ResellerPaymentsInfo/>,
    },
    {
        path: Routes.order(),
        component: <Order/>,
        children: []
    },
    {
        path: Routes.stories(),
        component: <StoryPageModal/>,
        children: []
    },
    {
        path: Routes.customers,
        component: <Customer/>,
        children: []
    },
    {
        path: Routes.referralCheckouts,
        component: <ReferralCheckouts/>,
        children: []
    },
    {
        path: Routes.contacts,
        component: <Contacts/>,
        children: []
    },
    {
        path: Routes.categories,
        component: <Categories/>,
        children: []
    },
    {
        path: Routes.users,
        component: <Users/>,
        children: []
    },
    {
        path: Routes.contactsAndCustomersRoute,
        component: <ContactsAndCustomers/>,
        children: []
    },
    {
        path: Routes.chat(),
        component: <Chat/>,
        children: []
    },
    {
        path: Routes.advancedCategoryRoute,
        component: <AdvancedCategory/>,
        children: []
    },
    {
        path: Routes.LTVRoute,
        component: <LTV/>,
        children: []
    },
    {
        path: Routes.aboutUs,
        component: <AboutUs />,
        children: []
    },
    {
        path: Routes.orders,
        component: <Orders/>,
        children: []
    },
    {
        path: Routes.shoporders,
        component: <Orders/>,
        children: []
    },
    // {
    //     path: Routes.notifications,
    //     component: <NotificationsList/>,
    //     children: []
    // },
    {
        path: Routes.editMainSettings,
        component: <EditMainSettings/>,
        children: []
    },
    {
        path: Routes.docs,
        component: <DocsList/>,
        children: []
    },
    {
        path: Routes.categoriesProductsList(),
        component: <CategoryProductList/>,
        children: []
    },
    // {
    //     path: Routes.productPage(),
    //     component: <ProductPageModal/>,
    //     children: []
    // },
    {
        path: Routes.withoutShopPlans,
        component: <ShopPlansList/>,
    },
]);
export const modalRoutes = (): React.ReactElement[] => createRoutes([
    // {
    //     path: Routes.productPage(),
    //     component: <ProductPageModal/>,
    //     children: []
    // },
    {
        path: Routes.userAnswersWithQuestions(),
        component: <UserAnswerInfo/>,
        children: []
    },
    {
        path: Routes.cart,
        component: <CartModal/>,
        children: []
    },
]);

// @ts-ignore
export const analyticsRoutes = (): React.ReactElement[] => createRoutes([
    {
        path: Routes.analyticsDashboard,
        component: <AnalyticsDashboard/>,
        children: []
    },

    {
        path: Routes.advancedAnalytics,
        component: <AdvancedAnalyticsPage/>,
        children: []
    },
    {
        path: Routes.categoriesAnalytics,
        component: <CategoriesAnalyticsPage/>,
        children: []
    },
    {
        path: Routes.productsAnalytics,
        component: <ProductsAnalyticsPage/>,
        children: []
    },
    {
        path: Routes.usersAnalytics,
        component: <UsersAnalyticsPage/>,
        children: []
    },
    {
        path: Routes.RFMAnalytics,
        component: <ChartRFM/>,
        children: []
    },

]);

// @ts-ignore
export const warehouseRoutes = (): React.ReactElement[] => createRoutes([
    {
        path: Routes.warehousingDashboard,
        component: <WarehousingMainPage/>,
        children: []
    },

    {
        path: Routes.warehousingInventoryProducts,
        component: <WarehouseInventoryProducts/>,
        children: []
    },

    {
        path: Routes.warehousingProductsDetails,
        component: <WarehouseProductsDetails/>,
        children: []
    },

    {
        path: Routes.warehousingAllProductsInventory,
        component: <WarehouseInventoryProductsInventory/>,
        children: []
    },

    {
        path: Routes.warehousingOrdersDetail,
        component: <WarehouseOrdersDetail/>,
        children: []
    },


]);