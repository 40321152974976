import React, {useEffect, useRef, useState} from 'react';
import {DefaultBooleanField, DefaultInput, DefaultSelect, DefaultTextArea} from "../../../components/fieilds";
import {Field, Form, Formik, FormikHelpers} from "formik";
import DefaultModal from "../../../components/modal";
import {useDispatch} from "react-redux";
import {ThunkDispatch} from "@reduxjs/toolkit";
import {useAppSelector} from "../../../redux/hooks";
import {RootState} from "../../../redux/store";
import MediaSelect from "../../../utils/media/media_select";
import {getMediaTypes, MediaType} from "../../../models/media";
import {
    getSettingByKey,
    toastError
} from "../../../utils/funcions";
import {
    AttributesProperty,
    getAll as getAllProducts,
    ProductCreateCredentials,
    submit
} from "../../../redux/dashboard/products/productsAction";
import {BiRightArrowAlt} from "react-icons/bi";
import {IconArrowRight, IconInfoSquare, IconMinus, IconPlus} from "@tabler/icons-react";
import {FormikProps} from "formik/dist/types";
import Routes from "../../../utils/routing/routes";
import {Link} from "react-router-dom";
import {getHighlights} from "../../../redux/dashboard/highLights/highLightsAction";
import {getAll as getAllCategories} from "../../../redux/dashboard/categories/categoriesAction";
import {getCategoriesForAddProduct} from "../../../redux/dashboard/catForAddProd/catForAddProdAction";
import {getUserInfo} from "../../../redux/user/user/userAction";
import {getAllPlans} from "../../../redux/dashboard/plans/plansAction";
import {EightUserAction} from "../../../redux/dashboard/users/EightUserAction";
import {getAllUsersForSingleMainPage} from "../../../redux/dashboard/users/UsersAction";
import {getAllShops} from "../../../redux/dashboard/shopUsers/ShopUsersAction";

interface values {
    title: string;
    description: string;
    price: number;
    old_price: number;
    buy_price: number;
    categoryId: number;
    highLightId: string;
    quantity: number;
    key: string;
    value: string;
    is_product_active: boolean;
    inventory_warning: boolean;
    zero_inventory?: boolean;
    attributes: AttributesProperty[],
    // highLight: HighLight;
}

interface Attribute {
    name: string;
    key: string;
    value: string;
}

const AddProductModal = () => {

    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
    const {submitLoading} = useAppSelector((state: RootState) => state.adminProducts);
    const {highLights} = useAppSelector((state: RootState) => state.highlights);
    const [media, setMedia] = React.useState<File[] | null>(null);
    const [cover, setCover] = React.useState<File[] | null>(null);
    const cat = useAppSelector((state: RootState) => state.catForAddProdSlice.cat);
    const formikRef = useRef<FormikProps<any>>(null);
    const {isMerchantFilled} = useAppSelector((state: RootState) => state.user);
    const [attributes, setAttributes] = useState<Attribute[]>([{ name: '', key: '', value: '' }]);
    // const [highlightShow, setHighlight] = useState<boolean>(false);
    const [highlightShow, setHighlightShow] = useState<boolean>(true);

    const handleChangeHighlightShow = () => {
        setHighlightShow(!highlightShow);
    };
    const handleAddAttribute = () => {
        setAttributes([...attributes, { name: '', key: '', value: '' }]);
    };


    const handleDeleteAttribute = (index: number) => {
        const newAttributes = [...attributes];
        newAttributes.splice(index, 1);
        setAttributes(newAttributes);
    };

    const handleChangeAttribute = (index: number, e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        const newAttributes = [...attributes];
        newAttributes[index] = { ...newAttributes[index], [name]: value };
        setAttributes(newAttributes);
    };
    return (
        <DefaultModal id='addProduct'
                      label={'addProduct'}
                      modalBoxClasses={`md:!max-w-3xl md:!w-max lg:min-w-31/100 md:h-max h-full overflow-x-hidden px-3`}>
            <label htmlFor={'addProduct'} className={'cursor-pointer sticky -top-6 z-[40] px-2 py-3 bg-white w-full md:hidden flex'}>
                <span><IconArrowRight/></span>
                <span className={'mr-2'}>افزودن</span>
            </label>
            <Formik
                innerRef={formikRef}
                initialValues={{
                    title: '',
                    description: '',
                    price: 0,
                    old_price: 0,
                    buy_price: 0,
                    categoryId: 0,
                    quantity: 0,
                    highLightId: '',
                    key: '',
                    name: '',
                    value: '',
                    is_product_active: true,
                    inventory_warning: true,
                    zero_inventory: true,
                    attributes: attributes,
                }}
                // @ts-ignore
                onSubmit={

                    async (values: values) => {

                        try {
                            const credentials: ProductCreateCredentials = {
                                mediaUrls: media!,
                                mediaTypes: getMediaTypes(media!),
                                coverUrls: cover,
                                coverTypes: 'image',
                                // attributes: attributes,
                                ...values,
                            };
                            await dispatch(submit(credentials));
                            (document.querySelector("[for='addProduct']") as HTMLElement).click();
                        } catch (error) {
                            console.error(error);
                        }
                    }
                }
                //validation
                validate={(values: values) => {
                    const errors: any = {};
                    if (!values.title) {
                        errors.title = 'عنوان پست را وارد کنید';
                    }
                    if (!values.description) {
                        errors.description = 'توضیحات پست را وارد کنید';
                    }
                    if (!media) {
                        toastError('لطفا یک رسانه انتخاب کنید')
                    }
                    // @ts-ignore
                    if (media && media[0].size > 5000000) {
                        toastError('حجم عکس نباید بالای 5 مگابایت باشد')
                    }
                    return errors;
                }}>
                <div className={`md:h-max h-full w-full items-center ${media == null ? 'md:mt-0 mt-12' : 'mt-4'} flex`}>

                    <Form method="dialog" className={'w-full h-full'}>
                        <div className="flex md:flex-row justify-around flex-col">
                            <div className={`${media ? 'md:w-1/2' : 'w-full'}`}>
                                <MediaSelect mediaTypes={[MediaType.image, MediaType.video]}
                                             onFileSelect={(files: File[] | null) => {
                                                 setMedia(files);
                                             }}></MediaSelect>
                            </div>
                            {media &&
                                <div className="md:w-5"></div>}
                            {media &&
                                <div className="md:w-1/2">
                                    <div className='flex-wrap justify-start md:mt-0 mt-4 items-center w-full flex'>

                                        <DefaultInput
                                            label='عنوان'
                                            name="title"
                                            type="text"
                                            placeholder='پست'
                                        ></DefaultInput>

                                        <DefaultInput
                                            label='قیمت (ریال)'
                                            name="price"
                                            type="number"
                                            placeholder='123456'
                                            ></DefaultInput>
                                        <DefaultInput
                                            label='تعداد '
                                            name="quantity"
                                            type="number"
                                            placeholder='10'
                                        ></DefaultInput>

                                        <div className={'flex w-full items-start justify-end'}>
                                            {
                                                highlightShow ? <DefaultSelect
                                                    label='برچسب های موجود'
                                                    addDiv={<div
                                                        className={'flex rounded-full cursor-pointer text-[#4192EF]'}
                                                        onClick={handleChangeHighlightShow}>
                                                        افزودن برچسب
                                                        <IconPlus color={'#4192EF'}/>
                                                    </div>}
                                                    name="highLightId"
                                                    placeholder='انتخاب کنید'
                                                    className={'w-full'}
                                                    options={
                                                        highLights == null ? <div>
                                                            <div></div>
                                                        </div> : highLights.map((category) => {
                                                            return {
                                                                label: category.name,
                                                                value: category.name
                                                            }
                                                        }) as any
                                                    }
                                                ></DefaultSelect> : ''
                                            }

                                        </div>

                                        {!highlightShow ? <DefaultInput

                                            addDiv={<div
                                                className={'flex rounded-full cursor-pointer text-[#DE0046]'}
                                                onClick={handleChangeHighlightShow}>
                                                برچسب های موجود
                                                <IconMinus color={'#DE0046'}/>
                                            </div>}
                                            label='عنوان برچسب'
                                            name="highLightId"
                                            type="text"
                                            placeholder='عنوان برچسب'
                                        ></DefaultInput> : ''}


                                        {/*<div className={'flex items-center mb-2'}>*/}
                                        {/*    <IconInfoSquare color={'#DE0046'} size={18}/>*/}
                                        {/*    <span*/}
                                        {/*        className={'text-[11px] mr-2'}>اگر قصد افزودن به برچسب های موجود دارید نام برچسب را به دقت وارد کنید در غیر اینصورت نام جدید را وارد کنید.</span>*/}
                                        {/*</div>*/}

                                        {!highlightShow ? <div className={`w-full flex justify-center mb-4 flex-col`}>
                                            <label htmlFor="">کاور برچسب</label>
                                            <MediaSelect mediaTypes={[MediaType.image]}
                                                         onFileSelect={(files: File[] | null) => {
                                                             setCover(files);
                                                         }}></MediaSelect>
                                        </div> : ''}

                                        <DefaultTextArea
                                            name="description"
                                            label="توضیحات پست"
                                            placeholder="توضیحات پست را وارد کنید"
                                            // className={''}
                                        ></DefaultTextArea>

                                        <DefaultSelect
                                            label='دسته بندی'
                                            name="categoryId"
                                            placeholder='بدون دسته بندی'
                                            options={
                                                cat == null ? <div>
                                                    <div></div>
                                                </div> : cat.map((category) => {
                                                    return {
                                                        label: category.title,
                                                        value: category.id
                                                    }
                                                }) as any
                                            }
                                        ></DefaultSelect>



                                    </div>
                                    <div className="modal-action w-full flex justify-start">
                                        <button type="submit"
                                                className={`btn text-white ${submitLoading ? 'bg-gray-400' : "bg-[#4192EF] hover:scale-102 hover:shadow-soft-xs active:opacity-85"}`}>
                                            {
                                                submitLoading ? 'صبر کنید' : 'افزودن '
                                            }
                                        </button>
                                        <div className={'w-0.5 h-2'}></div>
                                        <label
                                            htmlFor="addProduct"
                                            className="btn bg-[#DE0046] text-white hover:scale-102 hover:shadow-soft-xs active:opacity-85">بستن
                                        </label>
                                    </div>
                                </div>}
                        </div>
                    </Form>
                </div>
            </Formik>
        </DefaultModal>
    );
};

export default AddProductModal;
