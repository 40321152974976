import React, { useState } from "react";
import { FaAngleLeft } from "react-icons/fa6";
import { Link, useLocation } from "react-router-dom";
import {
    IconPhotoPlus,
    IconBuildingWarehouse,
    IconHome,
    IconMoneybag,
    IconPackageImport,
    IconTruck
} from "@tabler/icons-react";
import Routes, {warehouseRoutes} from "../../../utils/routing/routes";
import { IPath } from "../../../utils/types";
import { IconType } from "react-icons";
import { Routes as RRR } from "react-router-dom";

interface IItem {
    title: string,
    icon: IconType,
    link?: IPath,
    subItems?: IItem[],
    onClick?: () => void,
    planId?: number,
    isFor?: boolean,
    itemVisibilityType: 'all' | 'shop' | 'user' | 'auth' | 'guest' | 'super_admin',
}

export const WarehousingMainPage = () => {
    let indexw: number | undefined = undefined;
    const [openItem, setOpenItem] = useState<number | null>(null);
    const itemsw: IItem[] = [
        {
            title: 'پست ها',
            icon: IconPackageImport,
            link: Routes.warehousingProductsDetails,
            itemVisibilityType: 'shop',
        },
        {
            title: 'موجودی پست ها',
            icon: IconPhotoPlus,
            link: Routes.warehousingInventoryProducts,
            itemVisibilityType: 'shop',
        },
        {
            title: 'موجودی انبار',
            icon: IconMoneybag,
            link: Routes.warehousingAllProductsInventory,
            itemVisibilityType: 'shop',
        },
        {
            title: 'مدیریت سفارشات',
            icon: IconTruck,
            link: Routes.warehousingOrdersDetail,
            itemVisibilityType: 'shop',
        },
    ];

    const location = useLocation();
    const { pathname } = location;
    indexw = pathname === 'profile' ? itemsw.length : itemsw.findIndex(item => (item.link as IPath | null)?.path === pathname);
    itemsw.forEach((item, i) => {
        if (item.subItems) {
            const subSelectedIndex: number = item.subItems?.findIndex(subItem => (subItem.link as IPath | null)?.path === pathname);
            if (subSelectedIndex !== -1) {
                indexw = i;
            }
        }
    });

    return (
        <div className={'w-full flex h-full'}>
            {/* Sidebar for Desktop */}
            <div
                className={`w-56 fixed right-0 py-2 min-h-[100vh] hidden md:flex flex-col items-center admin-analytics bg-[#F9F9F9] sm:overflow-auto`}
            >
                <div className={'flex w-[54%] justify-center hover:bg-[#D4AF3733] duration-300 min-h-[120px] mb-2 rounded-full items-center flex-col'}>
                    <IconBuildingWarehouse color={'#D4AF37'} size={45} />
                    <div className={'mt-5'}>انبارداری</div>
                </div>
                <div className={'px-1 w-full items-start flex flex-col'}>
                    {
                        itemsw.map((item, i) => {
                            return (
                                <div
                                    key={i + item.title}
                                    className={`${i === indexw ? "bg-[#D4AF37]" : ''} ${i === indexw ? "bg-[#D4AF37]" : item.subItems ? null : 'hover:bg-[#D4AF3733]'} flex  w-full mt-1 items-center rounded-lg px-3`}
                                >
                                    <Link
                                        //@ts-ignore
                                        to={item.link?.path}
                                          className={`${i === indexw ? "font-bold" : 'font-normal'} flex flex-col !items-start py-1.5 flex items-center w-full text-md`}>
                                        <div className="flex w-full ">
                                            <item.icon
                                                color={i === indexw ? "white" : '#121212'}
                                                style={i === indexw ? { strokeWidth: "1.5" } : { strokeWidth: "1.5" }}
                                                size={24} />
                                            <span
                                                className="text-black text-sm mr-4 duration-300 opacity-100 pointer-events-none ease-soft flex justify-between w-full items-center">
                                                <span
                                                    className={`${i === indexw ? "text-white" : 'text-[#121212]'}  hover:z-[9999]`}>{item.title}</span>
                                                {item.subItems && (
                                                    <FaAngleLeft
                                                        className={`mr-1 ease-nav-brand text-slate-400 transition-all duration-200 ${i === indexw ? "text-white" : 'text-[#121212]'}`
                                                            + (openItem === i ? " transform rotate-90" : "")
                                                        } />
                                                )}
                                            </span>
                                        </div>
                                    </Link>
                                </div>
                            )
                        })
                    }
                </div>
            </div>

            {/* Navigation Bar for Mobile */}
            <div className={'md:hidden fixed bottom-0 w-full bg-white shadow-lg'}>
                <div className={'grid grid-cols-4'}>
                    {
                        itemsw.map((item, i) => {
                            return (
                                <Link
                                    //@ts-ignore
                                    to={item.link?.path} key={i} className={'flex flex-col items-center py-2'}>
                                    <item.icon
                                        color={i === indexw ? "#D4AF37" : '#121212'}
                                        style={i === indexw ? { strokeWidth: "1.5" } : { strokeWidth: "1.5" }}
                                        size={24} />
                                    <span className={`${i === indexw ? "text-[#D4AF37]" : 'text-[#121212]'} text-xs mt-1`}>
                                        {item.title}
                                    </span>
                                </Link>
                            )
                        })
                    }
                </div>
            </div>

            <div className={'py-20 md:mr-60 w-full lg:pl-28 md:pl-20 pl-4'}>
                <RRR>
                    {warehouseRoutes()}
                </RRR>
            </div>
        </div>
    )
}
