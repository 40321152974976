import {DefaultResponse} from "../../mainSlice";
import {createAsyncThunk} from "@reduxjs/toolkit";
import CartRequests, {ICartArgument} from "../../../requests/cartRequests";
import {AxiosResponse} from "axios";
import {LaravelValidationErrorType} from "../../../utils/types";
import {toast422, toastError, toastSuccess} from "../../../utils/funcions";


export type CartResponse = DefaultResponse & {
    id: number
}
export const addToCart = createAsyncThunk(
    'cart/addToCart',
    async (credentials: ICartArgument): Promise<CartResponse>=> {
        try {
            const response: AxiosResponse = await CartRequests.changeCart(credentials)
            // 
            if (response.status === 200 || response.status === 201) {

                const data = response.data;
                toastSuccess('پست با موفقیت به سبد خرید اضافه شد.');
                return {status: response.status, data, id: credentials.productId}
            }else if (response.status === 422) {
                
                const errors = await response.data as LaravelValidationErrorType;
                toast422(errors);
                return {
                    status: response.status,
                    data: errors,
                    id: credentials.productId
                }
            }else {
                toastError();
                return {
                    status: response.status,
                    data: null,
                    id: credentials.productId
                }
            }
            return {
                status: 200,
                    data: null,
                id: credentials.productId
            }
        }catch (e) {
            // toastError();
            return {
                status: 500,
                data: null,
                id: credentials.productId
            }
        }
    }
)