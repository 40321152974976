import React, {useEffect, useRef, useState} from 'react';
import {DefaultBooleanField, DefaultInput, DefaultTextArea} from "../../../components/fieilds";
import {useDispatch, useSelector} from "react-redux";
import Store, {RootState} from "../../../redux/store";
import {Form, Formik, FormikHelpers} from "formik";
import {submit} from "../../../redux/dashboard/settings/settingsAction";
import {ThunkDispatch} from "@reduxjs/toolkit";
import {Link} from "react-router-dom";
import Routes from "../../../utils/routing/routes";
import {IconArrowRight, IconEdit, IconEditCircle} from "@tabler/icons-react";
import {updateProfile} from "../../../redux/user/user/userAction";
import {defaultModal, getSettingByKey, NetworkImage} from "../../../utils/funcions";
import {useAppSelector} from "../../../redux/hooks";
import {NeshanMap} from "../../../redux/components/NeshanMap/NeshanMap";
import {BiCurrentLocation} from "react-icons/bi";
import {openModal} from "../../../redux/components/modalSlice";
import {FormikProps} from "formik/dist/types";
import {parseNumber} from "react-advanced-cropper";
import MediaSelect from "../../../utils/media/media_select";
import {MediaType} from "../../../models/media";

export interface SettingCredentials {
    [key: string]: string;
}

interface values {
    username: string;
    f_name: string;
    l_name: string;
    description: string;
}

const EditMainSettings: React.FC = () => {

    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

    const {settingFields, settings, settingFamilies} = useSelector((state: RootState) => state.user);
    const [submitLoading, setSubmitLoading] = useState(false);
    const {shop} = useAppSelector((state: RootState) => state.user);
    const [initialValues, setInitialValues] = useState<SettingCredentials>({});
    const formikRef = useRef<FormikProps<any>>(null);

    const [location, setLocation] = useState<{
        lng: number,
        lat: number,
    }>()

    useEffect(() => {
        const data = settingFields.reduce((acc: SettingCredentials, settingField) => {
            acc[settingField.name] = settings.find(i => i.name === settingField.name)?.value ?? '';
            return acc;
        }, {} as SettingCredentials)

        for (let key in data) {
            if (data[key] === null) {
                data[key] = '';
            }
            formikRef.current!.setFieldValue(key, data[key]);
        }

        setLocation({
            // @ts-ignore
                    lat: parseNumber(settings.find(e => e.name === 'lat')?.value),
            // @ts-ignore
                    lng: parseNumber(settings.find(e => e.name === 'lng')?.value),
                })
            // }, 1000)
        // }

    }, []);
    const [media, setMedia] = React.useState<File[] | null>(null);

    // console.log('console.logggg', settings)

    return (
        <>

            <Link to={Routes.products.path}
                  className={'fixed bg-white w-full z-[9999] cursor-pointer md:hidden flex py-3 mb-2 px-4'}>
                <span><IconArrowRight/></span>
                <span className={'mr-2'}>تنظیمات</span>
            </Link>

            <Formik
                innerRef={formikRef}
                initialValues={
                    initialValues
                }
                onSubmit={
                    async (values: SettingCredentials, {setSubmitting}: FormikHelpers<SettingCredentials>) => {
                        if (!submitLoading) {
                            setSubmitLoading(true);

                            try {
                                await dispatch(submit(values));

                                setSubmitLoading(false);
                            } catch (e) {
                                setSubmitLoading(false);
                            }
                        }
                    }
                }
            >
                <Form className="md:px-6 px-4 pb-20 mt-6">
                    <div className='flex-col justify-start items-center w-full flex'>
                        <div className='flex flex-col w-full'>
                            <div className="modal-body mt-4 w-full">
                                <div className="flex md:flex-row flex-col justify-center md:items-start items-center">
                                    <div className={'bg-transparent rounded-full'}><NetworkImage url={shop!.avatar} alt={''} className={'bg-transparent rounded-full h-[130px] min-w-[130px] object-cover md:mt-0 mt-6 w-full'}/></div>
                                    <div className="px-5 lg:flex-row flex flex-col md:mt-0 mt-4 w-full">
                                        <DefaultInput
                                            label={'نام فروشگاه'}
                                            type='string'
                                            // disabled={true}
                                            value={settings.find(e => e.name === 'shop_name')?.value}
                                            className="w-1/3" name='shop_name'/>
                                        <div className={'w-0.5 h-2'}></div>
                                    </div>
                                    {location ? <div className={'btn bg-[#DE0046] border border-red-500 text-white mt-9'} onClick={() => {
                                        dispatch(openModal(
                                            {
                                                onConfirm: () => {
                                                    const data = Store.getState().modal.modalReturnData;
                                                    if (data.lat && data.lng) {
                                                        formikRef.current!.setFieldValue('lat', data.lat);
                                                        formikRef.current!.setFieldValue('lng', data.lng);
                                                        setLocation(undefined);
                                                        setTimeout(() => {
                                                            setLocation({
                                                                lat: data.lat,
                                                                lng: data.lng
                                                            })
                                                        }, 300)
                                                    }
                                                },
                                                content: <NeshanMap
                                                    lat={location.lat}
                                                    lng={location.lng}
                                                ></NeshanMap>,
                                                outerClassName: "md:max-w-[60vw] md:mx-[10px] h-full !p-0",
                                                className: "w-full max-w-full h-full",
                                            }
                                        ))
                                    }}>
                                        انتخاب موقعیت
                                        <BiCurrentLocation className={'text-white text-2xl'}/>
                                    </div> : ''}
                                </div>
                                <div className={'w-0.5 h-2'}></div>
                            </div>
                            {location && location?.lat && location?.lng ? <div className="h-[300px]">
                                 < NeshanMap
                                    lat={location?.lat}
                                    lng={location?.lng}
                                    ></NeshanMap>
                            </div>: ''}
                        </div>
                        <div className="divider"></div>
                        {
                            settingFamilies.map((family) => {
                                return !family.plan_id || shop!.plan_id!  >= family.plan_id ? (
                                    <>
                                        <div className="collapse bg-[#4192EF22]">
                                            <input type="checkbox"/>
                                            <div className="collapse-title flex text-[#4192EF] text-xl font-medium">
                                                <IconEdit/>
                                                <h2 className="text-lg mr-2">{family.local_family}</h2>
                                            </div>
                                            <div className="collapse-content bg-white">
                                                <div className="flex items-start w-full mt-5 flex-wrap">
                                                    {settingFields.filter(e => e.family === family.family).map((value, i) => {

                                                        if (value.type == 'boolean') {
                                                            return <DefaultBooleanField
                                                                parentClassName={`lg:w-1/5 w-full px-2 mt-5 ${value.classList ? value.classList : ''}`}
                                                                name={value.name}
                                                                placeholder={value.placeholder}
                                                            />
                                                        }
                                                        if (value.type == 'script') {
                                                            return <DefaultInput
                                                                className=""
                                                                parentClassName={`lg:w-1/3 w-full px-2 mt-5 ${value.classList ? value.classList : ''}`}
                                                                name={value.name} label={value.placeholder} type={value.type}
                                                                placeholder={value.title}/>
                                                        }
                                                        return <DefaultInput
                                                            className=""
                                                            parentClassName={`lg:w-1/3 w-full px-2 mt-5 ${value.classList ? value.classList : ''}`}
                                                            name={value.name} label={value.placeholder} type={value.type}
                                                            placeholder={value.title}/>
                                                    })}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="divider"></div>
                                    </>
                                ) : <div></div>
                            })
                        }

                    </div>
                    <div className="modal-action w-full flex justify-start">
                        <button type="submit"
                                className={`btn text-white ${submitLoading ? 'bg-gray-400' : "bg-[#4192EF] hover:scale-102 hover:shadow-soft-xs px-10 active:opacity-85"}`}>
                            بروزرسانی
                        </button>
                    </div>
                </Form>
            </Formik>
        </>

    );

};

export default EditMainSettings;
