import React, {useEffect, useState} from 'react';
import {useAppSelector} from "redux/hooks";
import store, {RootState} from "redux/store";
import {useDispatch, useSelector} from "react-redux";
import {ThunkDispatch} from "@reduxjs/toolkit";
import {serverAsset} from "../../../services/connectionConfig";
import {Link, useLocation} from "react-router-dom";
import Routes from "../../../utils/routing/routes";
import {MdAddShoppingCart, MdOutlineMoreHoriz} from "react-icons/md";
import {AiOutlineDelete, AiOutlineEdit} from "react-icons/ai";
import Product from "../../../models/product";
import {addToCart} from "../../../redux/dashboard/cart/addCartAction";
import {getSettingByKey, NetworkImage, pagination} from "../../../utils/funcions";
import dLoad from "../../../assets/img/cantLoadImage.webp";
import {LoadingTableShimmer} from "../../../components/basincComponents";
import {IconArrowRight, IconPhoneCall, IconShoppingCartPlus} from "@tabler/icons-react";
import {getProductsCategory} from "../../../redux/dashboard/categories/productsCategoryAction";
import {setPaginate, setPaginateToStart} from "../../../redux/dashboard/categories/productsCategorySlice";
import {DeleteModal} from "../../../components/modal";
import EditProductModal from "../products/edit";
import {DefaultResponse} from "../../../redux/mainSlice";
import {remove} from "../../../redux/dashboard/products/productsAction";
import {getAll} from "../../../redux/dashboard/discountCodes/discountCodesAction";

const CategoryProductList = () => {

    const {categoryList, catId, loading} = useAppSelector((state: RootState) => state.adminProductsCategory);
    const {shop, user, settings, isMerchantFilled} = useAppSelector((state: RootState) => state.user);
    const [changeSideNumber, setChangeSideNumber] = useState(0);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState<Product | null>(null);
    const [editingItem, setEditingItem] = useState<Product | null>(null);
    // const [deletedId, setDeletedId] = useState<number>(0);
    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
    const changeSideNewNumber = (numberSide: number) => {
        setChangeSideNumber(numberSide);
    }


    const handleDeleteClick = (product: Product) => {
        setSelectedItem(product);
        setIsModalOpen(true);
    };

    const {submitLoading} = useSelector((state: RootState) => state.cart);
    const changeCartValues = (id: number) => {
        if (submitLoading) return;
        dispatch(addToCart({
            quantity: 1,
            productId: id
        }));
    }

    useEffect(() => {

        const handlePagination = () => {
            if (!store.getState().adminProductsCategory.loading &&
                !store.getState().adminProductsCategory.loadingMore &&
                !store.getState().adminProductsCategory.isEnd) {
                dispatch(setPaginate());
                dispatch(getProductsCategory({}));
            }
        };

        const cleanup = pagination(handlePagination);
        
        return () => cleanup();

        // pagination(() => {
        //     if (!store.getState().adminProductsCategory.loading && !store.getState().adminProductsCategory.loadingMore && !store.getState().adminProductsCategory.isEnd) {
        //         dispatch(setPaginate());
        //         dispatch(getProductsCategory({}));
        //     }
        // });

    });

    const handleDeleteConfirm = async () => {
        if (selectedItem) {
            // setDeletedId(selectedItem.id);
            setSelectedItem(null);
            setIsModalOpen(false);
            const result: DefaultResponse = (await dispatch(remove(selectedItem.id))).payload as DefaultResponse;
            if (result.status !== 200 && result.status !== 201) {
                // setDeletedId(0);
            }
        }
    };

    const highLights = useAppSelector((state: RootState) => state.highlights);

    return (
        <div className="flex flex-col pt-0 pb-16 md:pr-14">
            <Link to={Routes.categories.path} className={'cursor-pointer fixed top-0 z-[40] px-2 py-3 bg-white w-full md:hidden flex'}>
            <span><IconArrowRight/></span>
            <span className={'mr-2'}>پست ها</span>
        </Link>
            <div className='flex md:mt-4 mt-12  max-w-2xl'>

                <DeleteModal
                    title="حذف پست"
                    message="آیا از حذف این پست مطمئن هستید؟"
                    onSubmit={handleDeleteConfirm}
                    isOpen={isModalOpen}
                    handleIsOpen={setIsModalOpen}
                ></DeleteModal>
                <EditProductModal
                    product={editingItem}
                    onClose={(isOpen: boolean) => {
                        setEditingItem(null);
                    }}
                ></EditProductModal>
                <button onClick={() => changeSideNewNumber(0)}
                        className={`flex flex-1 py-2 mr-[2px] justify-center items-center ${changeSideNumber === 0 ? "border-b-[3px] border-black" : "border-none"}`}>
                    <div>

                        <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.782654 17.4348V1H17.2174V17.4348H0.782654Z"
                                  stroke={changeSideNumber === 1 ? "#B8B8B8" : "#101010"}
                                  strokeWidth="1.5"/>
                            <path d="M6.26086 1L6.26086 18.2174" stroke={changeSideNumber === 1 ? "#B8B8B8" : "#101010"}
                                  strokeWidth="1.5"/>
                            <path d="M0 11.9565L17.2174 11.9565" stroke={changeSideNumber === 1 ? "#B8B8B8" : "#101010"}
                                  strokeWidth="1.5"/>
                            <line x1="11.7065" y1="1" x2="11.7065" y2="18.2174"
                                  stroke={changeSideNumber === 1 ? "#B8B8B8" : "#101010"} strokeWidth="1.5"/>
                            <line x1="0.782654" y1="6.51086" x2="18" y2="6.51086"
                                  stroke={changeSideNumber === 1 ? "#B8B8B8" : "#101010"} strokeWidth="1.5"/>
                        </svg>
                    </div>
                    <span className={`text-sm mr-2 ${changeSideNumber === 1 ? "text-[#B8B8B8]" : "text-black"}`}>
                        نمایش گروهی
                    </span>
                </button>
                <button onClick={() => changeSideNewNumber(1)}
                        className={`flex flex-1 py-2 mr-[2px] justify-center items-center ${changeSideNumber === 1 ? "border-b-[3px] border-black" : "border-none"}`}>
                    <div>
                        <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 7.70787V1H19V7.70787H1Z"
                                  stroke={changeSideNumber === 0 ? "#B8B8B8" : "#101010"} strokeWidth="1.5"/>
                            <path d="M1 17.7696V11.0618H19V17.7696H1Z"
                                  stroke={changeSideNumber === 0 ? "#B8B8B8" : "#101010"} strokeWidth="1.5"/>
                        </svg>

                    </div>
                    <span className={`text-sm mr-2 ${changeSideNumber === 0 ? "text-[#B8B8B8]" : "text-black"}`}>
                        نمایش تکی
                    </span>
                </button>
            </div>
            {
                loading ? <div className={'flex justify-center items-center w-full'}>
                </div> : <div className={' max-w-2xl'}>
                    {
                        changeSideNumber === 0 ?
                            <div
                                className={`flex flex-wrap justify-start pb-1.5 w-full`}>
                                {
                                    categoryList.map(category => category!.map((hello) =>
                                        hello.products!.map((product) => <div
                                            className="group aspect-square relative w-1/3 md:p-0.5 p-[0.5px]">

                                            <Link to={Routes.product(product.id).path} className={'w-full h-full'}>
                                                <div
                                                    className={'w-full h-full cursor-pointer p-0'}>
                                                    {
                                                        product.media.length === 0 ? <img
                                                                className="w-full h-full p-0 object-cover bg-transparent"
                                                                src={dLoad}
                                                                alt={""}/> :
                                                            <div onDrop={() => {
                                                                setEditingItem(product);
                                                            }}
                                                                 className={'w-full h-full justify-center items-center flex '}
                                                            >
                                                                <img
                                                                    src={serverAsset(product.media[1].thumbnail)}
                                                                    alt=""
                                                                    className="w-full object-cover justify-center items-center flex h-full bg-transparent" // No blur here
                                                                />
                                                                {/*<img*/}
                                                                {/*    className="w-full h-full m-0 p-0 object-cover bg-transparent"*/}
                                                                {/*    // style={box}*/}
                                                                {/*    src={serverAsset(product.media[0].thumbnail)}*/}
                                                                {/*    alt={product.title}/>*/}
                                                            </div>

                                                    }
                                                </div>
                                            </Link>
                                        </div>)
                                    ))
                                }
                            </div> :

                            <div>

                                <div className='w-full flex justify-center px-2'>

                                    <div
                                        className={`flex flex-wrap  pb-1.5 w-full`}>
                                        {
                                            loading ? <LoadingTableShimmer/> :
                                                categoryList.map(category => category!.map((hello) =>
                                                        hello.products!.map((product) =>

                                                                <div className="group  relative w-full mt-2 ">
                                                                    <div
                                                                        className={'flex items-start justify-between w-full mt-2 pl-4'}>
                                                                        <div className={'flex'}>
                                                                            {
                                                                                shop!.avatar === null ? <img
                                                                                        className="w-[50px] h-[50px] rounded-full"
                                                                                        src={dLoad}
                                                                                        alt={""}/> :
                                                                                    <Link to={Routes.aboutUs.path} className="rounded-full">
                                                                                        <div
                                                                                            className={`rounded-full relative ${highLights.highLights != null && highLights.highLights!.length != 0 ? "bg-gradient-to-b from-[#DE0046] to-[#F7A34B]" : "bg-white"} flex w-[50px] h-[50px] justify-center items-center `}>

                                                                                            <div
                                                                                                className='rounded-full relative bg-white flex w-[47px] h-[46px] justify-center items-center'>

                                                                                                <NetworkImage
                                                                                                    data-te-img="https://mdbcdn.b-cdn.net/img/Photos/Slides/2.webp"
                                                                                                    alt=""
                                                                                                    className="object-cover flex justify-center items-center w-[45px] h-[45px] data-[te-lightbox-disabled]:cursor-auto rounded-full"
                                                                                                    url={
                                                                                                        shop!.avatar
                                                                                                    }/>
                                                                                            </div>
                                                                                        </div>
                                                                                    </Link>
                                                                            }

                                                                            <div className={'flex flex-col justify-start mr-2'}>
                                                <span
                                                    className={'text-[14px] '}>{settings.find(e => e.name === 'shop_name')?.value}</span>
                                                                                <span
                                                                                    className={'text-[12px] mt-1 ml-2'}>{shop!.description != null ? shop!.description.length > 40 ? shop!.description.substring(0, 40) + '...' : shop!.description : "بیوگرافی ندارد."}</span>
                                                                            </div>

                                                                        </div>
                                                                        <div
                                                                            className="flex cursor-pointer w-4 h-4 transition-all duration-300">

                                                                            {user!.id === shop!.id && (
                                                                                <div className="rounded-lg">
                                                                                    <div
                                                                                        className="dropdown dropdown-left p-1 rounded-lg">
                                                                                        <label tabIndex={0}
                                                                                               className="bg-transparent cursor-pointer ">
                                                                                            <MdOutlineMoreHoriz size={24}
                                                                                                                color="gray   "/>
                                                                                        </label>
                                                                                        <ul tabIndex={0}
                                                                                            className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-44">
                                                                                            <li
                                                                                                onClick={() => {
                                                                                                    setEditingItem(product);
                                                                                                }}
                                                                                                className="px-1 p-0 bg-transparent border-none cursor-pointer hover:bg-gray-400 hover:bg-opacity-50 rounded-lg items-start">
                                                                                                <div
                                                                                                    className="px-1 bg-transparent hover:bg-transparent">
                                                                                                    <AiOutlineEdit
                                                                                                        onClick={() => {
                                                                                                            setEditingItem(product);
                                                                                                        }
                                                                                                        }
                                                                                                        size={24}/>
                                                                                                    ویرایش
                                                                                                </div>
                                                                                            </li>
                                                                                            <li

                                                                                                onClick={() => {
                                                                                                    handleDeleteClick(product);
                                                                                                }}
                                                                                                className="px-1 p-0 bg-transparent border-none cursor-pointer hover:bg-gray-400 hover:bg-opacity-50 rounded-lg items-start">
                                                                                                <div
                                                                                                    className="px-1 text-[#DE0046] bg-transparent hover:bg-transparent">
                                                                                                    <AiOutlineDelete size={24}/>
                                                                                                    حذف
                                                                                                </div>
                                                                                            </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>)}
                                                                        </div>
                                                                    </div>

                                                                    <div className={'h-[350px] flex items-start mt-2'}>
                                                                        {
                                                                            product.media.length == 0 ? <img
                                                                                    className="w-full h-full border-none object-cover"
                                                                                    src={dLoad}
                                                                                    alt={""}/> :

                                                                                <div
                                                                                    className="carousel w-full h-[350px]">
                                                                                    {
                                                                                        product.media
                                                                                            .filter((_, i) => i !== 0) // فیلتر کردن اولین آیتم
                                                                                            .map((e, i) => (
                                                                                                <div id={`slide${i + 2}`} // تنظیم آیدی برای هر اسلاید
                                                                                                     className="carousel-item flex justify-start h-[350px] border-none shadow-none relative w-full mr-2">
                                                                                                    <div className="relative w-full flex justify-center shadow-none">
                                                                                                        <img
                                                                                                            src={serverAsset(e.thumbnail)}
                                                                                                            alt=""
                                                                                                            className="object-contain md:w-full border-none shadow-none h-[350px] items-center"
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>
                                                                                            ))
                                                                                    }

                                                                                </div>
                                                                        }
                                                                    </div>
                                                                    <div className={'mt-6 mb-4'}>
                                <span>

                                <div className={
                                    'md:hidden flex w-full justify-center mt-2'
                                }>
                                    {
                                        product.media.map((media, e) =>
                                            <div className={'w-2 h-2 rounded-full bg-gray-500 mr-0.5'}></div>
                                        )
                                    }
                                </div>
                    <p className={'text-[16px] font-bold'}>{product.title}</p>
                    <p className={'mt-1 text-[13px] font-md whitespace-pre-wrap'}>{product.description != null ? product.description :
                        <span>توضیحاتی ندارد.</span>}</p>
                            </span>
                                                                        <div
                                                                            className={'mt-[12px] w-full flex justify-end'}>
                                                                            {
                                                                                user!.is_super_admin || !isMerchantFilled ?

                                                                                    <a href={`tel:${settings.find(e => e.name === 'phone_number')?.value}`}
                                                                                       className={'btn btn-guest cursor-pointer border-none rounded-lg bg-[#4192EF] flex justify-center py-2 text-[14px] font-normal text-white'}>
                                                                                    <IconPhoneCall/>
                                                                                        <span className={'mr-2'}>تماس با ما</span>

                                                                                    </a> :
                                                                            <div onClick={() => changeCartValues(product.id)}
                                                                                 className={'btn btn-guest cursor-pointer border-none rounded-lg bg-[#4192EF] flex justify-center py-2 text-[14px] font-normal text-white '}>
                                                                                <IconShoppingCartPlus/>
                                                                                <span className={'mr-2'}>افزودن به سبد خرید</span>
                                                                            </div>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <hr/>
                                                                    {/*<img className="w-full h-full object-cover" src={serverAsset(product.media[0].thumbnail)}*/}
                                                                    {/*     alt={product.title}/>*/}
                                                                </div>
                                                        )
                                                ))
                                        }
                                    </div>
                                </div>

                            </div>
                    }
                </div>
            }
        </div>
    );
};

export default CategoryProductList;
